<app-header1></app-header1>
<!--====== Banner part start ======-->
	<section class="banner-section">
		<div class="banner-slider" id="bannerSlider">
			<div class="single-banner" style="background-image: url(assets/img/banner/01.jpg);">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="banner-content">
							
								<h1 data-animation="fadeInUp" data-delay="1s">
									Let's connect for <br> Business
								</h1>
								<ul class="btn-wrap">
									<li data-animation="fadeInLeft" data-delay="1.2s">
										<a href ="https://apps.apple.com/us/app/jcom/id1582305234" target="_blank"><img src="assets/img/appstore.png" style="width: 150px;margin-top: 10px;" alt="Image"></a> 
										<a href="https://play.google.com/store/apps/details?id=com.jcom" target="_blank"> <img src="assets/img/googleplay.png" style="width: 150px;margin-top: 10px;margin-left: 10px;"  alt="Image"></a>
																	  
									</li>
									<!-- <li data-animation="fadeInRight" data-delay="1.4s">
										<a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
									</li> -->
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="banner-shapes">
					<div class="one"></div>
					<div class="two"></div>
					<div class="three"></div>
					<div class="four"></div>
				</div>
			</div>
			<div class="single-banner" style="background-image: url(assets/img/banner/02.jpg);">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="banner-content">
							
								<h1 data-animation="fadeInUp" data-delay="1s">
									Grow Your Business<br> With JCOM
								</h1>
								<ul class="btn-wrap">
									<li data-animation="fadeInLeft" data-delay="1.2s">
										<a href ="https://apps.apple.com/us/app/jcom/id1582305234" target="_blank"><img src="assets/img/appstore.png" style="width: 150px;margin-top: 10px;" alt="Image"></a> 
										<a href="https://play.google.com/store/apps/details?id=com.jcom" target="_blank"> <img src="assets/img/googleplay.png" style="width: 150px;margin-top: 10px;margin-left: 10px;"  alt="Image"></a>
																	  
									</li>
									<!-- <li data-animation="fadeInRight" data-delay="1.4s">
										<a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
									</li> -->
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="banner-shapes">
					<div class="one"></div>
					<div class="two"></div>
					<div class="three"></div>
					<div class="four"></div>
				</div>
			</div>
			<div class="single-banner" style="background-image: url(assets/img/banner/01.jpg);">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="banner-content">
							
								<h1 data-animation="fadeInUp" data-delay="1s">
									Connecting Business Worldwide
								</h1>
								<ul class="btn-wrap">
									<li data-animation="fadeInLeft" data-delay="1.2s">
										<a href ="https://apps.apple.com/us/app/jcom/id1582305234" target="_blank"><img src="assets/img/appstore.png" style="width: 150px;margin-top: 10px;" alt="Image"></a> 
										<a href="https://apps.apple.com/us/app/jcom/id1582305234" target="_blank"> <img src="assets/img/googleplay.png" style="width: 150px;margin-top: 10px;margin-left: 10px;"  alt="Image"></a>
																	  
									</li>
									<!-- <li data-animation="fadeInRight" data-delay="1.4s">
										<a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
									</li> -->
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="banner-shapes">
					<div class="one"></div>
					<div class="two"></div>
					<div class="three"></div>
					<div class="four"></div>
				</div>
			</div>
		</div>

		<div class="search-wrap">
			<a routerLink='/' class="search-icon"><i class="far fa-search"></i></a>
		</div>
	</section>


	<!-- <section class="fact-section grey-bg">
		<div class="container">
			<div class="fact-boxes row justify-content-between align-items-center">
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-graphic"></i>
						</div>
						<h2>{{tables}}</h2>
						<p class="title">Tables</p>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-teamwork"></i>
						</div>
						<h2>{{members}}</h2>
						<p class="title">Members</p>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-target-1"></i>
						</div>
						<h2>{{connect}}</h2>
						<p class="title">Connects</p>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-plan"></i>
						</div>
						<h2>{{gnote}}</h2>
						<p class="title">G-Note</p>
					</div>
				</div>
			</div>
		</div>
	</section> -->
	<!--====== Banner part end ======-->

	<!--====== About Section start ======-->
	<section class="about-section about-illustration-img section-gap">
		<div class="container">
			<div class="illustration-img">
				<img src="assets/img/illustration/01.png" alt="Image">
			</div>
			<div class="row no-gutters justify-content-lg-end justify-content-center">
				<div class="col-lg-6 col-md-10">
					<div class="about-text">
						<div class="section-title left-border mb-40">
							<span class="title-tag">About Us</span>
							<h2 class="title">Jaycees Chamber of Commerce</h2>
						</div>
						<p class="mb-25">
							JCOM is a Business area program exclusively for JCI members.
						</p>
						<p>
							The objective of the program is to develop the Overall Business Growth of JCI Members,through various platforms						</p>
						<ul class="about-list">
						
						</ul>
						<a routerLink='/about' class="main-btn">Learn More</a>
					</div>
				</div>
			</div>
		</div>
	</section>

	
	<!--====== About Section end ======-->

	<!--====== Service Part Start ======-->
	<section class="service-section shape-style-one section-gap grey-bg">
		<div class="container">
			<!-- Section Title -->
			<div class="section-title text-center both-border mb-30">
				<!-- <span class="title-tag">Comany Services</span> -->
				<h2 class="title">What is JCOM ?</h2>
				<h4>We Provide Most Exclusive Business Opportunities for JCI Members</h4>
			</div>
			<!-- Services Boxes -->
			<div class="row service-boxes justify-content-center">
				<div class="col-lg-3 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms"
					data-wow-delay="400ms">
					<div class="service-box text-center">
						<div class="icon">
							<img src="assets/img/icons/02.png" alt="Icon">
						</div>
						<h3><a routerLink='/'>GROWTH
							CALL</a></h3>
						<a routerLink='/' class="service-link">
							<i class="fal fa-long-arrow-right"></i>
						</a>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInUp" data-wow-duration="1500ms"
					data-wow-delay="600ms">
					<div class="service-box text-center">
						<div class="icon">
							<img src="assets/img/icons/04.png" alt="Icon">
						</div>
						<h3><a routerLink='/'>SOLUTION
							BOARD</a></h3>
						<a routerLink='/' class="service-link">
							<i class="fal fa-long-arrow-right"></i>
						</a>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInRight" data-wow-duration="1500ms"
					data-wow-delay="800ms">
					<div class="service-box text-center">
						<div class="icon">
							<img src="assets/img/icons/03.png" alt="Icon">
						</div>
						<h3><a routerLink='/'>SCHOOLING <br></a></h3>
						<a routerLink='/' class="service-link">
							<i class="fal fa-long-arrow-right"></i>
						</a>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInRight" data-wow-duration="1500ms"
					data-wow-delay="800ms">
					<div class="service-box text-center">
						<div class="icon">
							<img src="assets/img/icons/01.png" alt="Icon">
						</div>
						<h3><a routerLink='/'>STRATEGIC
							PARTNERSHIP</a></h3>
						<a routerLink='/' class="service-link">
							<i class="fal fa-long-arrow-right"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="dots-line">
			<img src="assets/img/lines/07.png" alt="Image">
		</div>
	</section>
	<!--====== Service Part End ======-->

	<!--====== Video Start ======-->
	<!-- <section class="video-section bg-img-c section-gap" style="background-image: url(assets/img/video-bg/video-1.jpg);">
		<div class="container">
			<div class="row align-items-center justify-content-between">
				<div class="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
					<div class="video-text">
						<div class="section-title left-border mb-30">
							<span class="title-tag">Watch Videos</span> 
							<h2 class="title">
								See How JCOM Works
							</h2>
						</div>
						<p>
							JCOM Table meets every week for Business Development of it’s members.
						</p>
						<a routerLink='/table' class="main-btn">Browse JCOM Tables</a>
					</div>
				</div>
				<div class="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
					<div class="video-btn text-md-center wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="400ms">
						<a routerLink='/' class="play-btn popup-video">
							<img src="assets/img/icons/play.svg" alt="">
							<i class="fas fa-play"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="line-shape">
			<img src="assets/img/lines/08.png" alt="Line">
		</div>
	</section> -->
	<!--====== Video end ======-->
	<section class="team-section section-gap">
		<div class="container">
			<!-- Section Title -->
			<div class="section-title both-border text-center">
				<!-- <span class="title-tag">Meet Our </span> -->
				<h2 class="title">#Let Us Connect 4 Business Growth</h2>
			</div>
		</div>
	</section>		

	<!--====== Why Choose Us Part Start ======-->
	<section class="wcu-section box-style">
		<div class="container">
			<div class="wcu-inner">
				<div class="row align-items-center justify-content-center">
					<div class="col-lg-6">
						<div class="wcu-image text-center text-lg-left wow fadeInUp" data-wow-duration="1500ms"
							data-wow-delay="400ms">
							<img src="assets/img/illustration/02.png" alt="Image">
						</div>
					</div>
					<div class="col-lg-6 col-md-10">
						<div class="wcu-text">
							<div class="section-title left-border mb-40">
								<span class="title-tag">Who Can Join</span>
								<h2 class="title">Want to be a Part of JCOM?</h2>
							</div>
							<p>
								Members are individuals who are either active or associate or Alumni members of JCI, can Apply for JCOM and have paid the current year subscription of JCOM & JCI India Approval							</p>
							<ul class="wcu-list">
								<!-- <li>
									<i class="far fa-check-circle"></i> Specialist Company Advisor Team
								</li>
								<li>
									<i class="far fa-check-circle"></i> Understanding Machines OpenS tandard

								</li>
								<li>
									<i class="far fa-check-circle"></i> Can Data Visualization Improve Mobile
								</li> -->
							</ul>

							
							<a href ="https://apps.apple.com/us/app/jcom/id1582305234" target="_blank"><img src="assets/img/appstore.png" style="width: 150px;margin-top: 10px;" alt="Image"></a> 
							<a href="https://play.google.com/store/apps/details?id=com.jcom" target="_blank"> <img src="assets/img/googleplay.png" style="width: 150px;margin-top: 10px;margin-left: 10px;"  alt="Image"></a>
														  
						 
						</div>
					</div>
				</div>
				<img src="assets/img/lines/03.png" alt="shape" class="line-shape-one">
				<img src="assets/img/lines/04.png" alt="shape" class="line-shape-two">
			</div>
		</div>
	</section>
	<!--====== Why Choose Us Part End ======-->

	<!--====== Fact Part Start ======-->

	<!--====== Fact Part End ======-->

	<!--====== Team Section Start ======-->
	<section class="team-section section-gap">
		<div class="container">
			<!-- Section Title -->
			<div class="section-title mb-40 both-border text-center">
				<!-- <span class="title-tag">Meet Our </span> -->
				<h2 class="title">Leadership Team</h2>
			</div>

			<!-- Team Boxes -->
			<div class="row team-members" id="teamSliderOne">
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/Naional President- KARTHI.jpeg" alt="TeamMember">
						</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink=''>JFS M.K. KARTHIKEYAN</a></h3>
							<span class="pro">National President</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/Naional President- JC ANSHU SARAF.jpeg" alt="TeamMember">
								
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC ANSHU SARAF</a></h3>
							<span class="pro">Imm. Past National President
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/SG- BENEDICT K.M3.jpg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>MR. BENEDICT K.M.</a></h3>
							<span class="pro">Secretary General
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/chairman-didar.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JFS DIDARJIT SINGH LOTEY</a></h3>
							<span class="pro">NATIONAL CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				
				
			</div>
			<div class="row team-members" id="teamSliderOne">
				
				<!-- <div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/JCOM NOM COORDINATOR- JC DEVESH MARODIYA.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC DEVESH MARODIYA</a></h3>
							<span class="pro">NOM Coodinator
							</span>
						</div>
					</div>
				</div> -->
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/1 JC HARPREET SINGH.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC HARPREET SINGH</a></h3>
							<span class="pro">ZONE I CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/2 JC Anshul Agrawal.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC Anshul Agrawal</a></h3>
							<span class="pro">ZONE II CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/3  JC ASHWANI  KUMAR.jpg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC ASHWANI  KUMAR</a></h3>
							<span class="pro">ZONE III CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/4 JC MANGESH KUMAR.JPG" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC MANGESH KUMAR</a></h3>
							<span class="pro">ZONE IV CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/5 JC SAURABH RAHTOGI.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC SAURABH RAHTOGI</a></h3>
							<span class="pro">ZONE V CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/6 JC PRAKASH LOKWANI.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC PRAKASH LOKWANI</a></h3>
							<span class="pro">ZONE VI CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/7 JC BHUVNESH VORA.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC BHUVNESH VORA</a></h3>
							<span class="pro">ZONE VII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/8 JC VISHAL SHAH.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC VISHAL SHAH</a></h3>
							<span class="pro">ZONE VIII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/9 JC AMITESH PATHAK.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC AMITESH PATHAK</a></h3>
							<span class="pro">ZONE IX CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/10 JC VINOD GUPTA.JPG" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC VINOD GUPTA</a></h3>
							<span class="pro">ZONE X CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/11 JC THEJUS SHENOY.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC THEJUS SHENOY</a></h3>
							<span class="pro">ZONE XI CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/12 JC ALPESH KANUNGO.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC ALPESH KANUNGO</a></h3>
							<span class="pro">ZONE XII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/13 JC AMIT BHASALI.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC AMIT BHASALI</a></h3>
							<span class="pro">ZONE XIII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/14 JC YOGESH.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC YOGESH</a></h3>
							<span class="pro">ZONE XIV CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/15 JC SHASHIRAJ RAI.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC SHASHIRAJ RAI</a></h3>
							<span class="pro">ZONE XV CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/16 JC SHANKAR GANDHIRAJAN.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC SHANKAR GANDHIRAJAN</a></h3>
							<span class="pro">ZONE XVI CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/17 JC R MAHENDRAN.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC R MAHENDRAN</a></h3>
							<span class="pro">ZONE XVII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/18  JC L RADHAKRISHNAN.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC L RADHAKRISHNAN</a></h3>
							<span class="pro">ZONE XVIII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/19 JC SAJITH KUMAR V K.JPG" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC SAJITH KUMAR V K</a></h3>
							<span class="pro">ZONE XIX CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/20 JC JINU MADHAVAN.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC JINU MADHAVAN</a></h3>
							<span class="pro">ZONE XX CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/21 JC SUBEESH T P.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC SUBEESH T P</a></h3>
							<span class="pro">ZONE XXI CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/22 JC MADHU MOHAN.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC MADHU MOHAN</a></h3>
							<span class="pro">ZONE XXII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/23 JC ASHOK KUMAR.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC ASHOK KUMAR</a></h3>
							<span class="pro">ZONE XXII CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/24 JC UDAY KADAMBA.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC UDAY KADAMBA</a></h3>
							<span class="pro">ZONE XXIV CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/25 jc deepak bhajanka.jpeg" alt="TeamMember">
							
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/'>JC deepak bhajanka</a></h3>
							<span class="pro">ZONE XXV CHAIRMAN
							</span>
						</div>
					</div>
				</div>
				
			</div>
		
		</div>
	</section>
	<!--====== Team Section End ======-->


	<!--====== Latest Post Start ======-->
	<app-footer1></app-footer1>
<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <div class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Team Details</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Team Details</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </div>
    <!--====== Breadcrumb part End ======-->

    <!--====== Member Details Start ======-->
    <section class="member-details-wrapper section-gap-top">
        <div class="container">
            <div class="member-details">
                <div class="member-picture-wrap">
                    <div class="member-picture">
                        <img src="assets/img/team/01.jpg" alt="TeamMember">
                    </div>
                </div>
                <div class="member-desc">
                    <h3 class="name">John N. Holmes</h3>
                    <span class="pro">Business Developer</span>
                    <p>
                        Sedut perspiciatis unde omnis iste natus error sit voluptatem accusant doloremque laudantium
                        totam rem aperiam eaquipsa quae ab illo inventore veritatis
                    </p>
                    <p>
                        Quasi architecto beatae vitae dicta sunt explica boemo enim ipsam volupt
                    </p>
                </div>
                <div class="member-contact-info">
                    <h3 class="title">Get In Touch</h3>
                    <span class="title-tag">Contact Me</span>
                    <ul class="contact-info">
                        <li><i class="far fa-phone"></i> <strong>+012 (345) 6789</strong></li>
                        <li><a routerLink='/team-detail'><i class="far fa-envelope"></i> support@gmail.com</a></li>
                        <li><i class="far fa-map-marker-alt"></i> Broklyn Street USA</li>
                    </ul>
                    <ul class="social-links">
                        <li><a routerLink='/team-detail'><i class="fab fa-facebook-f"></i></a></li>
                        <li><a routerLink='/team-detail'><i class="fab fa-twitter"></i></a></li>
                        <li><a routerLink='/team-detail'><i class="fab fa-instagram"></i></a></li>
                        <li><a routerLink='/team-detail'><i class="fab fa-behance"></i></a></li>
                        <li><a routerLink='/team-detail'><i class="fab fa-dribbble"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!--====== Member Details End ======-->

    <!--====== Skill Section Start ======-->
    <section class="skill-section skill-section-two">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <!-- Skill Text Block -->
                    <div class="skill-text">
                        <div class="section-title mb-40 left-border">
                            <span class="title-tag">Company Skills</span>
                            <h2 class="title">We Have Experience <br> For Business Planning</h2>
                        </div>
                        <p>
                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium doloremque
                            laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
                        </p>

                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="skill-progress-bars">
                        <div class="skill-progress">
                            <div class="title d-flex justify-content-between">
                                <span>Marketing Strategy</span>
                                <span>85%</span>
                            </div>
                            <div class="progressbar-wrap">
                                <div class="progressbar" data-width="85">
                                </div>
                            </div>
                        </div>
                        <div class="skill-progress">
                            <div class="title d-flex justify-content-between">
                                <span>Digital Marketing</span>
                                <span>95%</span>
                            </div>
                            <div class="progressbar-wrap">
                                <div class="progressbar" data-width="95">
                                </div>
                            </div>
                        </div>
                        <div class="skill-progress">
                            <div class="title d-flex justify-content-between">
                                <span>Web Design</span>
                                <span>72%</span>
                            </div>
                            <div class="progressbar-wrap">
                                <div class="progressbar" data-width="72">
                                </div>
                            </div>
                        </div>
                        <div class="skill-progress">
                            <div class="title d-flex justify-content-between">
                                <span>IT Consulting</span>
                                <span>79%</span>
                            </div>
                            <div class="progressbar-wrap">
                                <div class="progressbar" data-width="79">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Skill Section End ======-->

    <!-- Line -->
    <div class="container">
        <div class="line-bottom"></div>
    </div>

    <!--====== Project section Start ======-->
    <section class="project-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-8">
                    <!-- Section Title -->
                    <div class="section-title left-border">
                        <span class="title-tag">Latest Project</span>
                        <h2 class="title">We Complate Much More Latest Project</h2>
                    </div>
                </div>
                <div class="col-lg-5 col-md-4">
                    <div class="view-moore-btn text-md-right mt-30 mt-md-0">
                        <a routerLink='/portfolio-one' class="main-btn">View More Project</a>
                    </div>
                </div>
            </div>

            <!-- Project Boxes -->
            <div class="row project-boxes mt-80 justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/01.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Free Consulting</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/02.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>How To Business</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/03.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Design Strategy</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Project section End ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
<app-header1></app-header1>
 <style>
      select:required:invalid {
        color: #666;
      }
      option[value=""][disabled] {
        display: none;
      }
      option {
        color: #000;
      }
    </style>

<section class="about-section about-illustration-img section-gap">
    <div class="container">
 
            <!-- FAQ Page Title -->
            <div class="container" style="text-align: -webkit-center;">
                <div class="col-lg-6" style="align-items: center;">
                  <div class="section-title left-border" >
                    
                    </div>
                
                </div>

                <div class="col-lg-12" style="align-items: center;">
                
                  <div class="col-lg-12">
                    <div class="contact-form">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-10">
                                <div class="section-title left-border" style="margin-bottom: 40px;">
                                    <h2 class="title">Browse Tables</h2>
                               </div>
                           
        
                                <form [formGroup]="registrationForm">
                                    <div class="row">
                                     

                                        <div class="col-lg-4">
                                            <label>Select Country</label><br>
                                            <div class="input-group mb-30">
                                               
                                                <select formControlName="Country" class="form-control">
                                
                                                    <option disabled selected>Select Country</option>
                                               
                                                    <option>{{Countrys}}</option>
                                               
                                                </select>
                                                <div *ngIf="f.Country.touched && f.Country.invalid" class="alert alert-danger">
                                
                                                    <div *ngIf="f.Country.errors.required">Country is required.</div>
                                    
                                                </div>
                                            </div>
                                        </div>



                                        

                                        <div class="col-lg-4">
                                            <label>Select Zone</label><br>
                                            <div class="input-group mb-30">
                                                
                                                <select formControlName="Zone" class="form-control"  (change)="loadlom($event.target.value)">
                                                    <option disabled selected>Select Zone</option>
                                                
                                                    <option *ngFor="let zone of Zones?.zone_details" [value]="zone.zone">{{zone.zone_name}}</option>

                                                </select>
                                                <div *ngIf="f.Zone.touched && f.Zone.invalid" class="alert alert-danger">
                                
                                                    <div *ngIf="f.Zone.errors.required">Zone is required.</div>
                                    
                                                </div>
                                            </div>
                                        </div>



                                        <div class="col-lg-4">
                                            <label>Select Table</label><br>
                                            <div class="input-group mb-30">
                                               
                                                <select formControlName="table" class="form-control">
                                                    <option disabled selected>Select Table</option>
                                         
                                                    <option *ngFor="let table of tables" [value]="table.table">{{table.table_name}}</option>
                                                </select>
                                                <div *ngIf="f.table.touched && f.table.invalid" class="alert alert-danger">
                                                    <div *ngIf="f.table.errors.required">Table is required</div>
                                                </div>
                                            </div>
                                        </div>


                                     
                                        <div class="col-12 text-center">
                                            <button type="button" (click)="onAddSubmit()" class="main-btn">Submit</button>
                                        </div>








                                        
                                    </div>
                                </form>


                            </div>
                        </div>

                      
                    </div>
                </div>
           

                </div>

                <div [hidden]="!isShowDiv">
                    <div class="col-lg-12" style="display: table-header-group;">
                        <div class="row" style="text-align: inherit;">
                            <table style="border: none;border-collapse: inherit;padding: 30px;">
                               <tr>
                                   <td style="border: none;padding-left: 30px;"><b>TableName</b></td> <td style="border: none;padding-left: 30px;"> {{memebers?.table_name}}</td>
                                   <td style="border: none;padding-left: 30px;"><b>Total Members</b></td><td style="border: none;padding-left: 30px;"> {{memebers?.total_members}}</td>
                               </tr>
                               <tr>
                                 <td style="border: none;padding-left: 30px;"><b>Chairman</b></td><td style="border: none;padding-left: 30px;">{{memebers?.chairman}}</td> 
                                 <td style="border: none;padding-left: 30px;"><b>Vice Chairman</b></td><td style="border: none;padding-left: 30px;">{{memebers?.vice_chairman}}</td>
                             </tr>
                             <tr>
                                 <td style="border: none;padding-left: 30px;"><b>Meeting Day</b></td><td style="border: none;padding-left: 30px;">{{memebers?.meeting_day}}</td>
                                 <td style="border: none;padding-left: 30px;"><b>Meeting Venue</b></td><td style="border: none;padding-left: 30px;">{{memebers?.meeting_venue}}</td>
                             </tr>
                             <tr>
                                 <td style="border: none;padding-left: 30px;"><b>Meeting Time</b></td><td style="border: none;padding-left: 30px;">{{memebers?.meeting_time}}</td>
                                 <td style="border: none;padding-left: 30px;"><b>Contact</b></td><td style="border: none;padding-left: 30px;">{{memebers?.contact}}</td>
                             </tr>
                            </table>
         
                         </div>
                </div>
         
               <!-- <div class="body">
                 <div class="table-responsive">
                      <table id="tableExport"
                      class="display table table-hover table-checkable order-column m-t-20 width-per-100">
                         <thead>
                             <tr>
                                 <th>S.No</th>
                                 <th>MemberName</th>
                                 <th>Designation</th>
                                 <th>Email</th>
                                 <th>Business Category</th>
                             </tr>
                         </thead>
                         <tbody>
                             <tr *ngFor="let Customer of Customers; let i=index">
                                 <td>{{i +1 }}</td>
                                 <td>{{Customer?.member_name}}</td>
                                 <td>{{Customer?.designation}}</td>
                                 <td>{{Customer?.email_id}}</td>
                                 <td>{{Customer?.business_category}}</td>  
                             </tr>
                         </tbody>
                     </table>
                 </div>
               </div> -->


                </div>



                <!-- <ngx-datatable #table class="material" [rows]="Customers" 
                columnMode="force" [headerHeight]="60" [footerHeight]="80" rowHeight="auto" [limit]="10">
    
    
    
                <ngx-datatable-column [width]="200" name="member_name">
                  <ng-template let-row="row" let-value="value" let-i="index" ngx-datatable-cell-template>
                    <div class="name-col-style">
                      <div>{{row.member_name}}</div>   
                    </div>
                  </ng-template>
                </ngx-datatable-column>
          
                <ngx-datatable-column name="business_category">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.business_category}}
                  </ng-template>
                </ngx-datatable-column>
             
    
              </ngx-datatable> -->


            </div>
        
           
</div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
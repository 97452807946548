<app-header1></app-header1>

    <section class="faq-section section-gap">
        <div class="container">
            <!-- FAQ Page Title -->
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Privacy Policy</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>   You acknowledge that JCOM may access, preserve and disclose your
                        information if required to do so by law or in a good faith belief that
                        such access preservation or disclosure is reasonably necessary to:
                        (a) comply with legal process; <br>(b) enforce the provisions of this TOU;
                        <br>(c) respond to claims that any content violates the rights of third
                        parties; <br>(d) respond to your requests for customer service; or <br>(e)
                        protect the rights, property or personal safety of JCOM, its users and
                        the public.<br>
                    </p>
                    <p>
                        <i class="fas fa-check"></i>    Our Privacy Policy, describes how we collect, process, store and
                        share the information you provide to us when you use jcombiz.com
                        and Services. You acknowledge that through your use of the Services,
                        your personal data will be collected and used (as set forth in the
                        Privacy Policy), including the transfer of your data to the servers kept
                        at Amazon Web Services, and/or other countries for storage, processing and use by
                        JCOM.
                    </p>
                 
                </div>
            </div>

        </div>
    </section>


    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
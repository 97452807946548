<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Our Shop</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Shop</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Shop Section Start ======-->
    <section class="shop-page-wrap section-gap">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 order-1">
                    <div class="row shop-top-bar justify-content-between">
                        <div class="col-lg-3 col-6 col-tiny-12">
                            <div class="product-search">
                                <form action="#">
                                    <input type="search" placeholder="Search Keywords">
                                    <button type="button"><i class="far fa-search"></i></button>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6 col-tiny-12">
                            <div class="product-shorting">
                                <select>
                                    <option value="0" selected>Short by</option>
                                    <option value="1">Deafult</option>
                                    <option value="2">By Name</option>
                                    <option value="3">By Price</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-10 order-3 order-lg-2">
                    <app-shop-sidebar></app-shop-sidebar>
                </div>
                <div class="col-lg-9 order-2 order-lg-2">
                    <div class="product-loop row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/01.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/02.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/03.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/04.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/05.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/06.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/07.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/08.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="assets/img/shop/09.png" alt="image">
                                    <div class="product-action">
                                        <a routerLink='/shop'><i class="far fa-shopping-cart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-heart"></i></a>
                                        <a routerLink='/shop'><i class="far fa-eye"></i></a>
                                    </div>
                                </div>
                                <div class="product-desc">
                                    <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star-half"></i>
                                        </li>
                                    </ul>
                                    <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                    <span class="price">$25.59</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pagination-wrap text-center">
                        <ul>
                            <li><a routerLink='/shop'><i class="far fa-angle-left"></i></a></li>
                            <li class="active"><a routerLink='/shop'>01</a></li>
                            <li><a routerLink='/shop'>02</a></li>
                            <li><a routerLink='/shop'>03</a></li>
                            <li><a routerLink='/shop'><i class="far fa-angle-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Shop Section End ======-->
    <app-footer1></app-footer1>
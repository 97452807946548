import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-traning',
  templateUrl: './traning.component.html',
  styleUrls: ['./traning.component.css']
})
export class TraningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

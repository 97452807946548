<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->

    <!--====== Breadcrumb part End ======-->

    <!--====== Job Category Start======-->

    <!--====== Job Category End======-->

    <!--====== Job List Start======-->

    <!--====== Job List End======-->

    <!--====== Client Area Start ======-->

    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
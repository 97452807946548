<app-header1></app-header1>

<section class="faq-section section-gap">
    <div class="container">
        <!-- FAQ Page Title -->
        <div class="row faq-page-title mb-60 align-items-center">
            <div class="col-lg-12">
                <div class="section-title left-border">
                    <h2 class="title">Training</h2>
                </div>
            </div>
            <div class="col-lg-12">
                <br>
                <br>
                <p>
                    <i class="fas fa-check"></i>
                    All new members will attend the New Member orientation Program and Members Onboard Program. 
                </p>

            </div>
        </div>

        <!-- FAQ LOOP -->
        <div class="accordion faq-loop grey-header" id="faqAccordion">

            <div class="card">
                <a href="assets/pdf/JCOMOP.pdf" target="_blank">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne">
                            JCOM Orientation Program
                            <span class="icons">
                                <i class="far fa-download"></i>
                            </span>
                        </h6>
                    </div>
                </a>
            </div>

            <div class="card">
                <a href="assets/pdf/JCOMMOP.pdf" target="_blank">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne1">
                            JCOM Members Onboarding Program
                            <span class="icons">
                                <i class="far fa-download"></i>
                            </span>
                        </h6>
                    </div>
                </a>
            </div>
            <div class="card">
                <a href="assets/pdf/JCOMOT.pdf" target="_blank">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne2">
                            JCOM Officers Training Program
                            <span class="icons">
                                <i class="far fa-download"></i>
                            </span>
                        </h6>
                    </div>
                </a>

            </div>
            <div class="card">

                <div class="card-header active-header">
                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne3">
                        JCOM Train the Trainers Program
                        <span class="icons">
                            <i class="far fa-download"></i>
                        </span>
                    </h6>
                </div>
            </div>




        </div>
        <!-- End Faq LOOP -->
    </div>
</section>
<!--====== Client Area End ======-->
<app-footer1></app-footer1>
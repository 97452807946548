<app-header3></app-header3>
<!--====== Banner Section start ======-->
    <section class="banner-section banner-section-three">
        <div class="banner-slider">
            <div class="single-banner">
                <div class="container-fluid container-1600">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="banner-content">
                                <span class="promo-text wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="400ms">business & consulting</span>
                                <h1 class="wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="500ms">
                                    Perfect Agency <br> For Innovative <br> Business
                                </h1>
                                <ul class="btn-wrap">
                                    <li class="wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <a routerLink='/about-us' class="main-btn">Get Started Now</a>
                                    </li>
                                    <li class="wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="700ms">
                                        <a routerLink='/service-one' class="main-btn main-btn-3">Our Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-7 d-none d-md-block">
                            <div class="banner-img text-right wow fadeInRight" data-wow-duration="1500ms"
                                data-wow-delay="800ms">
                                <img src="assets/img/illustration/04.png" alt="illustration">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-shapes">
                    <div class="one"></div>
                    <div class="two"></div>
                    <div class="three"></div>
                </div>
                <div class="banner-line">
                    <img src="assets/img/lines/17.png" alt="Image">
                </div>
            </div>
        </div>
    </section>
    <!--====== Banner Section end ======-->

    <!--====== Service Section Start ======-->
    <section class="service-section section-gap">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag">Comany Services</span>
                <h2 class="title">We Provide Most Exclusive <br> Service For Business</h2>
            </div>
            <!-- Services Boxes -->
            <div class="row service-boxes justify-content-center">
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/01.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Creative Idea</a></h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/02.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Business Strategy</a></h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/03.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Relationship</a></h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="1s">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/04.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Productivity</a></h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Service Section End ======-->

    <!--====== About Section start ======-->
    <section class="about-section-three section-gap">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                    <div class="about-text-three">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">About Us</span>
                            <h2 class="title">Professional Business <br> Guidance Agency </h2>
                        </div>
                        <p class="mb-25">
                            Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
                        </p>
                        <p>
                            Doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
                        </p>
                        <ul class="about-list">
                            <li> <i class="far fa-check"></i> Business & Consulting Agency</li>
                            <li> <i class="far fa-check"></i> Awards Winning Business Comapny</li>
                            <li> <i class="far fa-check"></i> Business & Consulting Agency</li>
                            <li> <i class="far fa-check"></i> Awards Winning Business Comapny</li>
                        </ul>
                        <a routerLink='/about-us' class="main-btn main-btn-4">Learn More</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                    <div class="about-tile-gallery">
                        <img src="assets/img/video-bg/poster-3.jpg" alt="Image" class="image-one wow fadeInRight"
                            data-wow-duration="1500ms" data-wow-delay="400ms">
                        <img src="assets/img/video-bg/poster-2.jpg" alt="Image" class="image-two wow fadeInRight"
                            data-wow-duration="1500ms" data-wow-delay="600ms">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== About Section end ======-->

    <!--====== Project Section Start ======-->
    <section class="project-section-two section-gap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Section Title -->
                    <div class="section-title text-center">
                        <span class="title-tag">Latest Project</span>
                        <h2 class="title">We Complate Much More<br>Latest Project</h2>
                    </div>
                </div>
            </div>
            <!-- Project Boxes -->
            <div class="row project-boxes mt-80 masonary-layout align-items-center">
                <div class="col-lg-4 col-sm-6 order-2 order-lg-1">
                    <div class="project-box wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/01.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Free Consulting</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 order-1 order-lg-2">
                    <div class="project-box wide-box wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="500ms">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/07.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>How To Build Your Business</a></h4>
                            <p>Nam libero tempore, cum soluta nobis est eligendi optio</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 order-4 order-lg-3">
                    <div class="project-box wide-box wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="600ms">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/08.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Management Corporate Office</a></h4>
                            <p>Nam libero tempore, cum soluta nobis est eligendi optio</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 order-3 order-lg-4">
                    <div class="project-box wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="700ms">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/06.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Business Monitor</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="view-more-btn text-center mt-50">
                <a routerLink='/portfolio-one' class="main-btn main-btn-3">View All Project</a>
            </div>
        </div>
    </section>
    <!--====== Project Section End ======-->

    <!--====== FAQ Section Start ======-->
    <section class="faq-section section-gap with-illustration with-shape grey-bg">
        <div class="container">
            <div class="faq-illustration-img">
                <img src="assets/img/illustration/03.png" alt="illustration">
            </div>
            <div class="row justify-content-lg-end justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="faq-content">
                        <div class="section-title mb-40 left-border">
                            <span class="title-tag">FAQ</span>
                            <h2 class="title">Frequently Asked Questions</h2>
                        </div>
                        <!-- FAQ LOOP -->
                        <div class="accordion faq-loop" id="faqAccordion">
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne">
                                        How To Create A Mobile App In Expo And Firebase

                                        <span class="icons">
                                            <i class="far fa-plus"></i>
                                        </span>
                                    </h6>
                                </div>

                                <div id="collapseOne" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        But must explain to you how all this mistaken idea odenouncing pleasure and
                                        praising pain was born and will give
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header active-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo">
                                        Smashing Podcast Episode With Ben How ?

                                        <span class="icons">
                                            <i class="far fa-minus"></i>
                                        </span>
                                    </h6>
                                </div>

                                <div id="collapseTwo" class="collapse show" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        But must explain to you how all this mistaken idea odenouncing pleasure and
                                        praising pain was born and will give
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree">
                                        Learning Resources Challenging Workshops ?

                                        <span class="icons">
                                            <i class="far fa-plus"></i>
                                        </span>
                                    </h6>
                                </div>

                                <div id="collapseThree" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        But must explain to you how all this mistaken idea odenouncing pleasure and
                                        praising pain was born and will give
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseFour">
                                        Micro-Typography: How To Space Kern ?

                                        <span class="icons">
                                            <i class="far fa-plus"></i>
                                        </span>
                                    </h6>
                                </div>
                                <div id="collapseFour" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        But must explain to you how all this mistaken idea odenouncing pleasure and
                                        praising pain was born and will give
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Faq LOOP -->
                    </div>
                </div>
            </div>
            <div class="circle-img" style="background-image: url(assets/img/faq-circle.png);"></div>
        </div>
    </section>
    <!--====== FAQ Section End ======-->

    <!--====== Fact Section Start ======-->
    <section class="fact-section-three section-gap">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                    <div class="fact-text">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Company Statistics</span>
                            <h2 class="title">We Are Established For Business & Consulting Since 25 Years</h2>
                        </div>
                        <ul class="fact-list">
                            <li> <i class="fas fa-check"></i> There are many variations of passages of LoreIpsum
                                available, but the majority have suffered</li>
                            <li> <i class="fas fa-check"></i> It uses a dictionary of over 200 Latin wor combined with a
                                handful of model sentence structure</li>
                            <li> <i class="fas fa-check"></i> Richard McClintock, a Latin profe hampden-sydney College
                                in Virginia, looked up one more</li>
                        </ul>
                        <a routerLink='/about-us' class="main-btn">Learn More</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                    <div class="fact-boxes row" id="factIsotpe">
                        <div class="col-6 col-tiny-12">
                            <div class="fact-box fact-box-three text-center">
                                <div class="icon">
                                    <i class="flaticon-graphic"></i>
                                </div>
                                <h2 class="counter">3568</h2>
                                <p class="title">Project Compalte</p>
                            </div>
                        </div>
                        <div class="col-6 col-tiny-12">
                            <div class="fact-box fact-box-three text-center mt-30">
                                <div class="icon">
                                    <i class="flaticon-plan"></i>
                                </div>
                                <h2 class="counter">3568</h2>
                                <p class="title">Project Compalte</p>
                            </div>
                        </div>
                        <div class="col-6 col-tiny-12">
                            <div class="fact-box fact-box-three text-center mt-30">
                                <div class="icon">
                                    <i class="flaticon-target-1"></i>
                                </div>
                                <h2 class="counter">3568</h2>
                                <p class="title">Project Compalte</p>
                            </div>
                        </div>
                        <div class="col-6 col-tiny-12">
                            <div class="fact-box fact-box-three text-center mt-30">
                                <div class="icon">
                                    <i class="flaticon-teamwork"></i>
                                </div>
                                <h2 class="counter">3568</h2>
                                <p class="title">Project Compalte</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Fact Section End ======-->

    <!--====== Team Section Start ======-->
    <section class="team-section">
        <div class="container-fluid p-70">
            <div class="section-title text-center both-border mb-80">
                <span class="title-tag"> Team Member </span>
                <h2 class="title">Meet Our Professional <br> Expertise</h2>
            </div>
            <!-- Team Slider -->
            <div class="team-members-two row" id="teamSliderTwo">
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/09.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>John N. Holmes</a></h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/08.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/07.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/06.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/05.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/09.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/08.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/07.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/06.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="team-member">
                        <div class="member-picture">
                            <img src="assets/img/team/05.jpg" alt="TeamMember">
                        </div>
                        <div class="member-desc">
                            <h3 class="name">John N. Holmes</h3>
                            <span class="pro">Business Developer</span>

                            <ul class="social-icons">
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/homepage3'><i class="fab fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Team Section end ======-->

    <!--====== Why Choose Us Section Start ======-->
    <section class="wcu-section section-gap">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-video wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                        <div class="video-poster-one bg-img-c"
                            style="background-image: url(assets/img/video-bg/poster-4.jpg);">
                        </div>
                        <div class="video-poster-two bg-img-c"
                            style="background-image: url(assets/img/video-bg/poster-5.jpg);">
                            <a href="https://www.youtube.com/watch?v=fEErySYqItI" class="popup-video">
                                <i class="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-text-two">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Why We Are Best ?</span>
                            <h2 class="title">We Have Many Reasons Why Choose Us</h2>
                        </div>
                        <p>
                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium doloremque
                            laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
                        </p>
                        <ul class="wcu-list clearfix">
                            <li>
                                <i class="far fa-check-circle"></i> Best Services
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Best Services
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Professional Advisor
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Professional Advisor
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Responsive Design
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Responsive Design
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Awesome Pricing
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Awesome Pricing
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Online Support
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Online Support
                            </li>
                        </ul>
                        <a routerLink='/contact-us' class="main-btn">Join With Us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Why Choose Us Section End ======-->

    <!--====== CTA Start ======-->
    <section class="cta-aection section-gap-bottom">
        <div class="container">
            <div class="cta-wrap bg-img-c" style="background-image: url(assets/img/lines/16.png);">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="cta-content text-center">
                            <div class="section-title both-border mb-30">
                                <span class="title-tag">Get A Quote</span>
                                <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                            </div>
                            <a routerLink='/contact-us' class="main-btn main-btn-3">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== CTA Start ======-->

    <!--====== Testimonials Section start ======-->
    <section class="testimonial-section-three bg-img-c" style="background-image: url(assets/img/testimonial/bg.jpg);">
        <div class="container">
            <div class="row justify-content-center no-gutters">
                <div class="col-lg-10">
                    <div class="testimonial-items" id="testimonialSliderThree">
                        <div class="testimonial-item text-center">
                            <div class="author-thumb">
                                <img src="assets/img/testimonial/author-9.png" alt="image">
                            </div>

                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fal fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    <span class="quote-bottom">
                                        <i class="fal fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>

                            <div class="author">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                        <div class="testimonial-item text-center">
                            <div class="author-thumb">
                                <img src="assets/img/testimonial/author-9.png" alt="image">
                            </div>

                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fal fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    <span class="quote-bottom">
                                        <i class="fal fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>

                            <div class="author">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                        <div class="testimonial-item text-center">
                            <div class="author-thumb">
                                <img src="assets/img/testimonial/author-9.png" alt="image">
                            </div>

                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fal fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    <span class="quote-bottom">
                                        <i class="fal fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>

                            <div class="author">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Testimonials Section end ======-->

    <!--====== Contact Section start ======-->
    <section class="contact-section boxed-style-with-map">
        <div class="container">
            <div class="contact-inner mt-negative grey-bg">
                <div class="row no-gutters">
                    <div class="col-lg-6">
                        <div class="contact-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60975.5149935516!2d-73.99735520603153!3d40.7015706118682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1598633530051!5m2!1sen!2sbd"
                                style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-form">
                            <div class="section-title left-border mb-30">
                                <span class="title-tag">Drop A Message</span>
                                <h2 class="title">Don’t Hesited To Contact Us</h2>
                            </div>

                            <form action="#">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="input-group mb-30">
                                            <input type="text" placeholder="Your Full Name">
                                            <span class="icon"><i class="far fa-user-circle"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group mb-30">
                                            <input type="email" placeholder="Your Email Address">
                                            <span class="icon"><i class="far fa-envelope-open"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group select mb-30">
                                            <select>
                                                <option value="subject" selected>Subject</option>
                                                <option value="1">Creative Idea</option>
                                                <option value="2">Relationship</option>
                                                <option value="3">Business Strategy</option>
                                                <option value="4">Productivity</option>
                                                <option value="5">It Solutions</option>
                                                <option value="5">Product Design</option>
                                            </select>
                                            <span class="icon"><i class="far fa-angle-down"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group textarea mb-30">
                                            <textarea placeholder="Write Message"></textarea>
                                            <span class="icon"><i class="far fa-pencil"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="main-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Contact Section start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Section End ======-->
    <app-footer2></app-footer2>
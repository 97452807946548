<app-header1></app-header1>

    <section class="faq-section section-gap">
        <div class="container">
            <!-- FAQ Page Title -->
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Fees Structure</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>   For enrolling the new member, registration fees will be     Rs.2000+18% GST   (non- refundable)  per year
                    </p>
                    <p>
                        <i class="fas fa-check"></i>  The renewal fee of Rs 3000 + 18% GST should be paid before January 1st of every year
                    </p>
                  <p>
                        <i class="fas fa-check"></i>Every member should pay the registration fees before the enrollment
                    </p>
                    <p>
                        <i class="fas fa-check"></i>The member has to pay the sitting fees on or before the meeting as decided        by their table executive board
                    </p>
                    <p>
                        <i class="fas fa-check"></i>Any further expenses for the table will be collected as per the decision       of the Executive Board
                    </p>
                    <p>
                        <i class="fas fa-check"></i>The terminated member based on the attendance can rejoin only by paying full registration fees if the executive committee of the table agrees
                    </p>
                </div>
            </div>

        </div>
    </section>


    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->

    <!--====== Breadcrumb part End ======-->

    <!--====== Contact Section start ======-->
    <section class="contact-section contact-page section-gap-top">
        <div class="container">
            <div class="contact-info">
                <div class="row justify-content-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div class="illustration-img text-center">
                            <img src="assets/img/illustration/man-with-earth-02.png" alt="Image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                        <div class="contact-info-content">
                            <div class="section-title left-border mb-40">
                                <span class="title-tag">Get In Touch</span>
                                <h2 class="title">Need Any Help?</h2>
                            </div>
                            <p>
                               
                            </p>

                            <ul>
                                <li class="phone">
                                    <a href="tel:+(022)-71117112"><i class="far fa-phone"></i>+(022)-71117112</a>
                                </li>
                                <li><i class="far fa-envelope-open"></i><a routerLink='/contact-us'>jcom@jciindia.in</a></li>
                                <li><i class="far fa-map-marker-alt"></i>JCI India National Head Quarters,<br>506 Windfall, Sahar Plaza, J.B. Nagar,
											Andheri (EAST) Mumbai 400 059</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact-form grey-bg">
                <div class="row no-gutters justify-content-center">
                    <div class="col-10">
                        <div class="section-title text-center mb-40">
                            <h2 class="title">Please Submit Your Details</h2>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" placeholder="Your Full Name">
                                        <span class="icon"><i class="far fa-user-circle"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="email" placeholder="Your Email Address">
                                        <span class="icon"><i class="far fa-envelope-open"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" placeholder="Your Phone">
                                        <span class="icon"><i class="far fa-phone"></i></span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group textarea mb-30">
                                        <textarea placeholder="Write Message"></textarea>
                                        <span class="icon"><i class="far fa-pencil"></i></span>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="button" class="main-btn">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        
    </section>
    <!--====== Contact Section start ======-->

    <!--====== Client Area Start ======-->

    <div class="container-fluid container-1600">
        <div class="contact-map">
            <iframe
                src="https://maps.google.com/maps?q=JCI%20INDIA%20NATIONAL%20HEAD%20QUARTER&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                style="width: 100%;margin-top: 200px;height: 500px;" allowfullscreen=""  tabindex="0"></iframe>
        </div>
    </div>

    <section class="client-section">
        <div class="container">
           
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <div class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Blog Grid</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Blog</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </div>
    <!--====== Breadcrumb part End ======-->

    <!--====== Blog Section Start ======-->
    <section class="blog-section section-gap-top">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <!-- Blog loop(Grid) -->
                    <div class="blog-loop grid-blog row justify-content-center">
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/04.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            How Performance Visiblety With GitLab CI And Hood
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/05.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Inspired Design Decisions With Max Huber Turne
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/06.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Mirage JS Deep Dive Unders Tanding Mirage JS Models
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/07.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Brighten Up Someone’s May With Max Huber Turne
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/08.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Why Collaborative Coding Is The Ultimate Career Hack
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/09.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Responsive Web & Desktop Development Flutter
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/10.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            How To Create Particle Trail Animation In JavaScript
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/11.jpg" alt="Image">
                                </div>
                                <div class="post-content">
                                    <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Inspired Design Decisions With Herb Lubalin Can
                                        </a>
                                    </h3>
                                    <p>
                                        Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                                        raperiaeaqupsa quae
                                    </p>
                                    <a routerLink='/blog-detail' class="post-link">
                                        Learn More <i class="far fa-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div class="pagination-wrap">
                        <ul>
                            <li><a routerLink='/blog-grid'><i class="far fa-angle-left"></i></a></li>
                            <li class="active"><a routerLink='/blog-grid'>01</a></li>
                            <li><a routerLink='/blog-grid'>02</a></li>
                            <li><a routerLink='/blog-grid'>03</a></li>
                            <li><a routerLink='/blog-grid'><i class="far fa-angle-right"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-8">
                    <!-- sidebar -->
                    <app-blog-sidebar></app-blog-sidebar>
                </div>
            </div>
        </div>
    </section>
    <!--====== Blog Section End ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-grid' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
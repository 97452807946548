<!-- sidebar -->
                    <div class="sidebar">
                        <!-- Search Widget -->
                        <div class="widget search-widget">
                            <form action="#">
                                <input type="text" placeholder="Search here">
                                <button type="button"><i class="far fa-search"></i></button>
                            </form>
                        </div>
                        <!-- Cat Widget -->
                        <div class="widget cat-widget">
                            <h4 class="widget-title">Category</h4>

                            <ul>
                                <li>
                                    <a href="javascript:void(0)">Financial Planning <span><i class="far fa-angle-right"></i></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Relationship Buildup <span><i class="far fa-angle-right"></i></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Investement Planning <span><i class="far fa-angle-right"></i></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Marketing Strategy <span><i class="far fa-angle-right"></i></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Product Strategy <span><i class="far fa-angle-right"></i></span></a>
                                </li>
                            </ul>
                        </div>
                        <!-- Recent Post Widget -->
                        <div class="widget recent-post-widget">
                            <h4 class="widget-title">Recent News</h4>

                            <div class="post-loops">
                                <div class="single-post">
                                    <div class="post-thumb">
                                        <img src="assets/img/sidebar/recent-01.jpg" alt="Image">
                                    </div>
                                    <div class="post-desc">
                                        <span class="date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                        <a routerLink='/blog-detail'>
                                            Smashing Podcast Epis With Rach Andrewe
                                        </a>
                                    </div>
                                </div>
                                <div class="single-post">
                                    <div class="post-thumb">
                                        <img src="assets/img/sidebar/recent-02.jpg" alt="Image">
                                    </div>
                                    <div class="post-desc">
                                        <span class="date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                        <a routerLink='/blog-detail'>
                                            Responsive Web And Desktop Develop
                                        </a>
                                    </div>
                                </div>
                                <div class="single-post">
                                    <div class="post-thumb">
                                        <img src="assets/img/sidebar/recent-03.jpg" alt="Image">
                                    </div>
                                    <div class="post-desc">
                                        <span class="date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                                        <a routerLink='/blog-detail'>
                                            Django Highlig Models Admin Harnessing
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Popular Tag Widget -->
                        <div class="widget popular-tag-widget">
                            <h4 class="widget-title">Popular Tags</h4>
                            <div class="tags-loop">
                                <a href="javascript:void(0)">Business</a>
                                <a href="javascript:void(0)">Corporate</a>
                                <a href="javascript:void(0)">HTML</a>
                                <a href="javascript:void(0)">Finance</a>
                                <a href="javascript:void(0)">Investment</a>
                                <a href="javascript:void(0)">CSS</a>
                                <a href="javascript:void(0)">Planing</a>
                                <a href="javascript:void(0)">Creative</a>
                            </div>
                        </div>
                        <!-- Author Infor Widget -->
                        <div class="widget author-widget">
                            <img src="assets/img/sidebar/author.jpg" alt="Image" class="author-img">
                            <h4 class="name">Brandon Johnston</h4>
                            <span class="role">Author</span>
                            <p>
                                Vero eos et accusamus et iustoys odio dignissimos ducimu blanditiis praesentium
                                voluptatum
                            </p>
                            <ul class="social-icons">
                                <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-behance"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-dribbble"></i></a></li>
                            </ul>
                        </div>
                        <!-- CTA Widget -->
                        <div class="widget cta-widget" style="background-image: url(assets/img/sidebar/cta.jpg);">
                            <h4 class="title">Need Any Consultations</h4>
                            <a href="javascript:void(0)" class="main-btn">Send Message</a>
                        </div>
                    </div>
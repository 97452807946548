<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <div class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Blog Details</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Blog Details</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </div>
    <!--====== Breadcrumb part End ======-->

    <!--====== Blog Section Start ======-->
    <section class="blog-section section-gap-top">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <!-- Blog Details -->
                    <div class="post-details-wrap">
                        <div class="post-thumb">
                            <img src="assets/img/blog/12.jpg" alt="Image">
                        </div>
                        <div class="post-meta">
                            <ul>
                                <li><i class="far fa-user"></i><a routerLink='/blog-detail'>Nichel Jhon</a></li>
                                <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-detail'>25 Aug 2020</a></li>
                                <li><i class="far fa-comments"></i><a routerLink='/blog-detail'>Comments (05)</a></li>
                            </ul>
                        </div>
                        <div class="post-content">
                            <h3 class="title">
                                Inspired Design Decisions With Herb Typography Can Be As Exciting As
                                Illustration & Photo
                            </h3>
                            <p>
                                But I must explain to you how all this mistaken idea of denouncing pleasure and praising
                                pain was born and I will give you a complete account of the system, and expound the
                                actual teachings of the great explorer of the truth, the master-builder of human
                                happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure,
                                but because those who do not know how to pursue pleasure rationally encounter
                                consequences that are extremely painful. Nor again is there anyone who loves or pursues
                                or desires to obtain pain of itself, because it is pain, but because occasionally
                                circumstances occur in which toil and pain can procure him some great pleasure. To take
                                a trivial example, which of us ever undertakes laborious physical exercise, except to
                                obtain some advantage from it but who has any right to find fault with a man who chooses
                            </p>

                            <blockquote>
                                Smashing Podcast Episode With Paul Boag What Is Conversion Optimization
                                <span class="author">Bailey Dobson</span>
                            </blockquote>

                            <h4 class="with-check"><i class="far fa-check-circle"></i> Inspired Design Decisions With
                                Otto Storch When Idea Copy</h4>

                            <p>
                                No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because
                                those who do not know how to pursue pleasure rationally encounter consequences that are
                                extremely painful. Nor again is there anyone who loves or pursues or desires to obtain
                                pain of itself, because it is pain, but because occasionally circumstances occur in
                                which toil and pain can procure him some great pleasure.
                            </p>

                        </div>
                        <div class="post-footer d-md-flex align-items-md-center justify-content-md-between">
                            <div class="post-tag">
                                <ul>
                                    <li class="title">Popular Tags :</li>
                                    <li><a routerLink='/blog-detail'>IT Consulting,</a></li>
                                    <li><a routerLink='/blog-detail'>Business,</a></li>
                                    <li><a routerLink='/blog-detail'>Corporate,</a></li>
                                </ul>
                            </div>
                            <div class="post-share">
                                <ul>
                                    <li class="title">Share :</li>
                                    <li><a routerLink='/blog-detail'><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
                                    <li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
                                    <li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Post Author -->
                    <div class="post-author-info">
                        <div class="author-img">
                            <img src="assets/img/details/post-author.jpg" alt="Image">
                        </div>
                        <div class="author-desc">
                            <h4>Nathan George</h4>
                            <p>
                                No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because
                                those who do not know how to pursue pleasure rationally encounter consequences that
                                aextremely painful. Nor again is there anyone who loves
                            </p>
                            <ul class="social-links">
                                <li><a routerLink='/blog-detail'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
                                <li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li>
                                <li><a routerLink='/blog-detail'><i class="fab fa-dribbble"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- Post Nav -->
                    <div class="post-nav row">
                        <div class="prev-post col-md-6">
                            <div class="img">
                                <img src="assets/img/sidebar/recent-01.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <span class="date"><i class="far fa-calendar-alt"></i> 25 Aug 2020</span>
                                <h5><a routerLink='/blog-detail'>Smashing Podcast Epis <br> With Rach Andrewe</a></h5>
                            </div>
                        </div>
                        <div class="next-post col-md-6">
                            <div class="content">
                                <span class="date">25 Aug 2020 <i class="far fa-calendar-alt"></i></span>
                                <h5><a routerLink='/blog-detail'>Implementing Infinite <br> Loading In React</a></h5>
                            </div>
                            <div class="img">
                                <img src="assets/img/sidebar/recent-03.jpg" alt="Image">
                            </div>
                        </div>
                    </div>
                    <!-- Related Post -->
                    <div class="related-post">
                        <h3 class="title">Related Post</h3>
                        <div class="latest-post-loop row ">
                            <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                                <div class="latest-post-box-two">
                                    <div class="post-thumb-wrap">
                                        <div class="post-thumb bg-img-c"
                                            style="background-image: url(assets/img/latest-post/01.jpg);">
                                        </div>
                                        <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug
                                            2020</span>
                                    </div>
                                    <div class="post-desc">
                                        <h3 class="title">
                                            <a routerLink='/blog-detail'>
                                                How Performance Visiblety With GitLab CI & Hood
                                            </a>
                                        </h3>
                                        <a routerLink='/blog-detail' class="post-link">
                                            Learn More <i class="far fa-long-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-10 col-tiny-12">
                                <div class="latest-post-box-two">
                                    <div class="post-thumb-wrap">
                                        <div class="post-thumb bg-img-c"
                                            style="background-image: url(assets/img/latest-post/02.jpg);">
                                        </div>
                                        <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug
                                            2020</span>
                                    </div>
                                    <div class="post-desc">
                                        <h3 class="title">
                                            <a routerLink='/blog-detail'>
                                                Inspired Design Decisions With Max Huber Turne
                                            </a>
                                        </h3>
                                        <a routerLink='/blog-detail' class="post-link">
                                            Learn More <i class="far fa-long-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Comments Template -->
                    <div class="comment-template">
                        <h3 class="title">Comments</h3>
                        <!-- Comments List -->
                        <ul class="comment-list">
                            <li>
                                <div class="author-img">
                                    <img src="assets/img/details/comment-1.png" alt="Image">
                                </div>
                                <div class="comment-content">
                                    <h5 class="author-name">John F. Medina<span class="date">25 July 2020</span></h5>
                                    <p>
                                        But I must explain to you how all this mistaken idea of denouncing pleasure and
                                        praising pawas born and I will give you a complete account
                                    </p>
                                    <a routerLink='/blog-detail' class="reply-btn">Reply <i class="far fa-long-arrow-alt-right"></i></a>
                                </div>
                                <ul class="children">
                                    <li>
                                        <div class="author-img">
                                            <img src="assets/img/details/comment-2.png" alt="Image">
                                        </div>
                                        <div class="comment-content">
                                            <h5 class="author-name">Jeffrey T. Kelly<span class="date">25 July
                                                    2020</span>
                                            </h5>
                                            <p>
                                                Again is there anyone who loves or pursues or desires to obtain paiits
                                                ecause it is pain, but because occasionally circumstances occur in which
                                            </p>
                                            <a routerLink='/blog-detail' class="reply-btn">Reply <i
                                                    class="far fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div class="author-img">
                                    <img src="assets/img/details/comment-3.png" alt="Image">
                                </div>
                                <div class="comment-content">
                                    <h5 class="author-name">Richard B. Zellmer<span class="date">25 July 2020</span>
                                    </h5>
                                    <p>
                                        Harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
                                        soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                                    </p>
                                    <a routerLink='/blog-detail' class="reply-btn">Reply <i class="far fa-long-arrow-alt-right"></i></a>
                                </div>
                            </li>
                        </ul>
                        <!-- Comments form -->
                        <div class="comment-form">
                            <h3 class="title">Leave A Reply</h3>
                            <form action="#">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="input-group mb-30">
                                            <input type="text" placeholder="Your Full Name">
                                            <span class="icon"><i class="far fa-user"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="input-group mb-30">
                                            <input type="email" placeholder="Your Email ">
                                            <span class="icon"><i class="far fa-envelope"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group textarea mb-30">
                                            <textarea placeholder="Write Message"></textarea>
                                            <span class="icon"><i class="far fa-pencil"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="main-btn">Send Reply</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-8">
                    <!-- sidebar -->
                    <app-blog-sidebar></app-blog-sidebar>
                </div>
            </div>
        </div>
    </section>
    <!--====== Blog Section End ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
<footer>
		<div class="container">
			<div class="footer-widget">
				<div class="row">
					<div class="col-lg-4 col-sm-5 order-1">
						<div class="widget site-info-widget">
							<div class="footer-logo" style="width: 50%;">
								<img src="assets/img/logo.png" alt="Finsa">
							</div>
							<p>To provide Business Development opportunities that empower young Entrepreneurs
								towards Business Growth.</p>
							<ul class="social-links">
								<li><a href="https://facebook.com/jcijcom" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg-8 col-sm-7 order-2">
						<div class="widget newsletter-widget">
							<h4 class="widget-title">Subscribe Our Newsletters</h4>
							<div class="newsletter-form">
								<form action="#">
									<input type="email" placeholder="Enter Your Email">
									<button type="button" class="main-btn">Subscribe Now</button>
								</form>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6 order-4">
						<div class="widget nav-widget">
							<h4 class="widget-title">Quick Links</h4>
							<ul>
								<li><a routerLink='/visionmission' href="javascript:void(0)">Vision&Mission</a></li>
								<li><a routerLink='/jcomworks' href="javascript:void(0)">How JCOM Works</a></li>
								<li><a routerLink='/jcomwork' href="javascript:void(0)">JCOM Board</a></li>
								<li><a routerLink='/tabletypes' href="javascript:void(0)">Table Types</a></li>
								<li><a routerLink='/table' href="javascript:void(0)">Browse Tables</a></li>
								<li><a routerLink='/membership' href="javascript:void(0)">Membership</a></li>
								<li><a routerLink='/training' href="javascript:void(0)">Training</a></li>
								<li><a routerLink='/starttable' href="javascript:void(0)">Start Table?</a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg-8 order-lg-4 order-8">
						<div class="row">
							<div class="col-lg-3 col-sm-6">
								<div class="widget nav-widget">
									<h4 class="widget-title">Other Links</h4>
									<ul>
										<li><a routerLink='/about' href="javascript:void(0)">About JCOM</a></li>
										<li><a routerLink='/PrivacyPolicy' href="javascript:void(0)">Privacy Policy</a></li>
										<li><a routerLink='/refund' href="javascript:void(0)">Refund/Cancellation</a></li>
										<li><a routerLink='/Pricing' href="javascript:void(0)">Pricing</a></li>
										<li><a routerLink='/Terms' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms And Conditions</a></li>

									</ul>
								</div>
							</div>
							<div class="col-lg-6 col-sm-6">
								<div class="widget nav-widget">
									<h4 class="widget-title">Downloads</h4>
									<ul>
										
										<li><a target="_blank" href='assets/pdf/JCOMOP.pdf' >JCOM Orientation Program</a></li>
										<li><a target="_blank" href='assets/pdf/JCOMMOP.pdf'>JCOM Members Onboarding Program</a></li>
										<li><a target="_blank" href='assets/pdf/JCOMOT.pdf'>JCOM Officers Training Program</a></li>
									
									</ul>
								</div>
							</div>
							<div class="col-lg-3 col-sm-6">
								<div class="widget contact-widget">
									<h4 class="widget-title">Contact Us</h4>
									<!-- <p>Untrammelled & nothing preven our being able</p> -->
									<ul class="contact-infos">
										<li>
											<a href="tel:+(022)-71117112">
												<i class="far fa-phone"></i>
												+(022)-71117112
											</a>
										</li>
										<li>
											<a href="jcom@jciindia.in">
												<i class="far fa-envelope-open"></i>
												jcom@jciindia.in
											</a>
										</li>
										<li> <i class="far fa-map-marker-alt"></i>JCI India National Head Quarters,<br>506 Windfall, Sahar Plaza, J.B. Nagar,
											Andheri (EAST) Mumbai 400 059</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				
				</div>
			</div>
			<div class="footer-copyright">
				<p class="copyright-text">
					<span>Copyright ©  2021  <a href="javascript:void(0)">JCOM</a></span>
					<span>Designed by <a href="https://neophron.in/">Neophron Tech</a></span>
					<a routerLink='/Terms' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms And Conditions</a>

				</p>

				<a href="javascript:void(0)" class="back-to-top"><i class="far fa-angle-up"></i></a>
			</div>
		</div>

		<!-- Lines -->
		<img src="assets/img/lines/01.png" alt="line-shape" class="line-one">
		<img src="assets/img/lines/02.png" alt="line-shape" class="line-two">
	</footer>
<app-header1></app-header1>


<section class="about-section about-illustration-img section-gap">
    <div class="container">
 
            <!-- FAQ Page Title -->
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Table Clasifications</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>  To start table minimum members required is 10 
                    </p>
                    <p>
                        <i class="fas fa-check"></i>  There can be a maximum of only 60 (sixty) members in a table
                    </p>
                    <p>
                        <i class="fas fa-check"></i>  There can be any number of tables in a town/city/ Country.
                    </p>
                    <p>
                        <i class="fas fa-check"></i> A Table can Start with 10 members but it should reach minimum 30       members within four months of time to continue. 
                    </p>
                </div>
            </div>




            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Live table</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>The members of the same area will meet physically every week on  a fixed day for 100 minutes duration and will discuss their business growth development activity as prescribed by JCOM 

                    </p>
                  
                </div>
            </div>



            
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Virtual Table</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>The members of a virtual table can anywhere from the same  country  will meet Virtually every week on a fixed day for 100 minutes duration and will discuss their business growth development activity as prescribed by JCOM 


                    </p>
                  
                </div>
            </div>

   
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Association Table</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>The members belonging to same type of business can start  association table. The members can meet once in a fort night and discuss about  their business growth developments.  There is no maximum limit for the number of  members.


                    </p>
                  
                </div>
            </div>

            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">International Virtual table</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i> The members of different countries  will meet Virtually  every week on a fixed day for 100 minutes duration and will discuss their business growth development activity as prescribed by JCOM
                    </p>    
                </div>
            </div>


       

     

</div>
</section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <!-- <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">About Us</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section> -->
    <!--====== Breadcrumb part End ======-->

    <!--====== About Section start ======-->

    <section class="about-section about-illustration-img section-gap">
        <div class="container">
            <div class="illustration-img">
                <img src="assets/img/illustration/logo.jpg" alt="Image">
            </div>
            <div class="row no-gutters justify-content-lg-end justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="about-text">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">About JCI India</span>
                            <h2 class="title">JUNIOR CHAMBER INTERNATIONAL</h2>
                        </div>
                        <p class="mb-25">
                            Junior Chamber International (JCI) is an international organization founded in 1915 at St Louis, USA, and having its International Head Quarters at Chesterfield, St. Louis, USA. It is an NGO, imparting training to youth, developing them and transforms them as active citizen, for sustainable positive change around the world. It works with many organizations including UN, for sustainable development globally.  JCI is spread in above 100 countries. Each Country is known as a National Organization Member (NOM). In India, JCI was launched in 1949. JCI India is the NOM affiliated to JCI, which is the international body
                        </p>

                        <p class="mb-25">
                            JCI India is a registered society under the Societies Registration Act XXI of 1860 as amended by the Societies Registration Act (Punjab Amendment Act 1957) as extended to the State of Delhi                    
                            <ul class="about-list">
                            
                        </ul>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="about-section about-illustration-img section-gap">
        <div class="container">
            <div class="illustration-img">
                <img src="assets/img/illustration/01.png" alt="Image">
            </div>
            <div class="row no-gutters justify-content-lg-end justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="about-text">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">About Us</span>
                            <h2 class="title">Jaycees Chamber of Commerce</h2>
                        </div>
                        <p class="mb-25">
                            We Provide Most Exclusive Business Opportunities for JCI Members
                        </p>
                        <p>
							The objective of the program is to develop the Overall Business Growth of JCI Members,through various platforms						</p>
						 <ul class="about-list">
                            
                        </ul>
                        <a routerLink='/about-us' class="main-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== About Section end ======-->

 
 
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
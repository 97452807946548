<app-header1></app-header1>

    <section class="faq-section section-gap">
        <div class="container">
            <!-- FAQ Page Title -->
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Refund Policy</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>   Our refund policy acknowledges that subject to applicable membership policies of JCOM,  if the payment is already done by you, and if your desired services are not delivered, JCOM will refund any payment you made for the service that has not been delivered.
                    </p>
                    <p>
                        <i class="fas fa-check"></i>  Any decision on refund is sole discretion of the JCOM Board, if any dispute arise upon any matter
                    </p>
                 
                </div>
            </div>

        </div>
    </section>


    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
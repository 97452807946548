<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Our Project</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Our Project</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Project section Start ======-->
    <section class="project-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <ul class="project-nav project-isotope-filter">
                        <li data-filter="*" class="active"> All Project </li>
                        <li data-filter=".item-one"> Business </li>
                        <li data-filter=".item-two"> Finaance </li>
                        <li data-filter=".item-three"> Development </li>
                        <li data-filter=".item-four"> Consulting </li>
                        <li data-filter=".item-five"> Web </li>
                        <li data-filter=".item-six"> Server </li>
                    </ul>
                </div>
            </div>

            <!-- Project Boxes -->
            <div class="row project-boxes project-isotope mt-60 justify-content-center">
                <div class="isotope-item col-lg-4 col-sm-6 item-one item-four item-five">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/05.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Free Consulting</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-six item-three item-two">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/03.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>How To Business</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-six item-four item-one item-two">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/14.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Design Strategy</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="isotope-item col-lg-8 col-sm-6 item-two item-six">
                    <div class="project-box wide-long-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/15.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Research Strategy</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-three item-five item-three">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/11.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>IT Consultations</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-six item-four item-two">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/13.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Business Monitor</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-five item-three item-two">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/01.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Free Consulting</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="isotope-item col-lg-8 col-12 item-four item-two item-five">
                    <div class="project-box wide-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/16.jpg);">
                            </div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Business Monitor</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Project section End ======-->

    <!--====== CTA Start ======-->
    <section class="cta-aection">
        <div class="container">
            <div class="cta-wrap bg-img-c" style="background-image: url(assets/img/lines/16.png);">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="cta-content text-center">
                            <div class="section-title both-border mb-30">
                                <span class="title-tag">Get A Quote</span>
                                <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                            </div>
                            <a routerLink='/contact-us' class="main-btn main-btn-3">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== CTA Start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
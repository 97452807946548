<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <!-- <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">About Us</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section> -->
    <!--====== Breadcrumb part End ======-->

    <!--====== About Section start ======-->

    <section class="faq-section section-gap">
        <div class="container">


            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h3 class="title">4 Weeks 4 Different Types of Table Meetings</h3>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i><b class="bol"> Growth Plan & Growth Call Meet</b> - Members will give and seek for business connections to each other as per the need of other members
                    </p>
                    <p>
                        <i class="fas fa-check"></i><b class="bol"> Solution Board Meet</b> - Any 5 Business Challenges of Members will be discussed and other members as well as solution board will provide solutions to those Challenges
                    </p>
                    <p>
                        <i class="fas fa-check"></i><b class="bol"> Learning Meet</b> - Audio Based Learning Meeting with Subject Expert/Consultant to sharpen or skills as well as gain Knowledge in the area of Business
                    </p>
                    <p>
                        <i class="fas fa-check"></i><b class="bol"> Cross Business Meet</b> - It will be Joint meet with Other JCOM Table to explore the Oppourtunity for more Business Connections as well as collaborations
                    </p>
                  
                </div>
            </div>
            
            <div class="accordion faq-loop grey-header" id="faqAccordion">
                
                <div class="card">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo">
                            Growth Call: 100 Minutes for 100% Growth
                        <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseTwo" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                               <p>
                                The first step to growing a business is presenting it to potential customers and brand ambassadors. JCOM trains its members on how to present their business and provides the opportunity to do so in front of their table pears during the growth call meeting. Members help each other in achieving what they seek. 
                               </p>
                            </ul>  
                       </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo2">
                            Solution: 100 Minutes for 100% Solution
                        <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseTwo2" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                               <p>
                                Every business, every enterprise are often faced with their own unique challenges. The nature of these challenges also changes continuously with the changing environment. As members business grows with JCOM, they are likely to come across new challenges as well. These challenges impede the growth of your business. Overcoming these challenges makes you a stronger, better businessman and sets the tone for further growth. JCOM provides this opportunity for its members to discuss the challenges faced by your business and to discover the best solutions.
                               </p>
                            </ul>  
                       </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo1">
                            100 Minutes for 100% Learning and Implementation
                        <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseTwo1" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                               <p>
                                To upgrade and update yourself is one of the most important keys to business success. JCOM provides  the opportunity to stay current, to remain aware of new ideas and developments, and to learn from business experts. Upskilling is a handy tool in path to success. JCOM arms its members with it. 
                              </p>
                            </ul>  
                       </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo3">
                            100 Minutes for 100% Partnership
                        <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseTwo3" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                               <p>
                                JCOM is business growth platform for members of JCI, which in turn is the largest youth organisation of its kind in the country and the world. The platform is designed in such a way that it allows individual members to journey from competition to cooperation through partnership and expansion opportunities shared among the Jaycee JCOM members. Your rotating and expanding peer base acts as a catalyst in their business growth.
                               </p>
                            </ul>  
                       </div>
                    </div>
                </div>

            </div>
             <br><br><br>
            <div class="tab-content" id="tab-history">
                <div class="tab-text-block right-image with-right-circle">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-8 col-md-10 order-2 order-lg-1">
                            <div class="block-text">
                                <h2 class="title">Table</h2>
                                 <p>
                                    The Individual business group in a town / city / Country will be called as JCOM Table
                                </p>
                               
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-10 order-1 order-lg-2">
                            <div class="block-image">
                                <img src="assets/img/feature/01.jpg" alt="Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-content" id="tab-business">
                <div class="tab-text-block left-image with-left-circle">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-4 col-md-10">
                            <div class="block-image">
                                <img src="assets/img/feature/02.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="block-text">
                                <h2 class="title">Members</h2>
                                <li>
                                    Individuals who are either Active, Associate or SMA Member of JCI, can apply for JCOM by
Annual JCOM Subscription & JCI India Approval.
                                </li>
                                
                                <li>
                                    An Individual member can join only one Live Table, any number of virtual Table and unlimited number of
Association Tables On Payment of corresponding subscription(s).
                                </li>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- <div class="tab-content" id="tab-team" >
                <div class="tab-text-block right-image with-right-circle">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-8 col-md-10 order-2 order-lg-1">
                            <div class="block-text">
                                <h2 class="title">Connect
                                </h2>
                                <p>
                                    Connect means recommending our friends and  relatives to get a business
                                </p>
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-10 order-1 order-lg-2">
                            <div class="block-image">
                                <img src="assets/img/feature/03.jpg" alt="Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="tab-content" id="tab-business">
                <div class="tab-text-block left-image with-left-circle">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-4 col-md-10">
                            <div class="block-image">
                                <img src="assets/img/feature/02.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <div class="block-text">
                                <h2 class="title">Guests</h2>
                               <li>
                                Any invited visitor who can be a prospective future JCOM Member are called Guests.
                               </li>
                               <li>
                                A visitor can be a non JCI Member but to become a JCOM member he / she should become a
                                member of JCI.
                               </li>
                               <li>
                                Guests may attend table meetings up to maximum Two.
                               </li>
                               <li>
                                A Group of Business Individuals in a Town / City / Country.
                               </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="tab-content" id="tab-Showcase" role="tabpanel">
                <div class="tab-text-block right-image with-right-circle">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-8 col-md-10 order-2 order-lg-1">
                            <div class="block-text">
                                <h2 class="title">Show case
                                </h2>
                                <p>
                                    Members are expected to present their product/ service  through an E promotion in a creative manner ( Videos, audio, flyers etc and a completion can be announced in the table)                                        
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-10 order-1 order-lg-2">
                            <div class="block-image">
                                <img src="assets/img/feature/05.jpg" alt="Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->


<!-- 
            <section class="video-section bg-img-c section-gap" style="background-image: url(assets/img/video-bg/video-1.jpg);">
                <div class="container">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
                            <div class="video-text">
                                <div class="section-title left-border mb-30">
                                    <span class="title-tag">Watch Videos</span> 
                                    <h2 class="title">
                                        See How JCOM Works
                                    </h2>
                                </div>
                                <p>
                                    JCOM Table meets every week for Business Development of it’s members.
        
        
                                </p>
                                <a routerLink='/table' class="main-btn">Browse JCOM Tables</a>
                            </div>
                        </div>
                        <div class="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
                            <div class="video-btn text-md-center wow fadeInUp" data-wow-duration="1500ms"
                                data-wow-delay="400ms">
                                <a routerLink='/' class="play-btn popup-video">
                                    <img src="assets/img/icons/play.svg" alt="">
                                    <i class="fas fa-play"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line-shape">
                    <img src="assets/img/lines/08.png" alt="Line">
                </div>
            </section> -->
        
        
            <section class="about-section about-illustration-img section-gap">
                <div class="container">
                    <div class="wcu-inner">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6">
                                <div class="wcu-image text-center text-lg-left wow fadeInUp" data-wow-duration="1500ms"
                                    data-wow-delay="400ms">
                                    <img src="assets/img/illustration/02.png" alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10">
                                <div class="wcu-text">
                                    <div class="section-title left-border mb-40">
                                        <span class="title-tag">Who Can Join</span>
                                        <h2 class="title">Want to be a Part of JCOM?</h2>
                                    </div>
                                    <p>
                                        Individuals who are either Active, Associate or SMA Member of JCI, can apply for JCOM by
Annual JCOM Subscription & JCI India Approval.
                                    </p>
                                    <ul class="wcu-list">
                                        <!-- <li>
                                            <i class="far fa-check-circle"></i> Specialist Company Advisor Team
                                        </li>
                                        <li>
                                            <i class="far fa-check-circle"></i> Understanding Machines OpenS tandard
            
                                        </li>
                                        <li>
                                            <i class="far fa-check-circle"></i> Can Data Visualization Improve Mobile
                                        </li> -->
                                    </ul>
            
                                 
                                
                                       <a href ="https://play.google.com/store/apps/details?id=com.jcom" target="_blank"><img src="assets/img/appstore.png" style="width: 198px;margin-top: 10px;" alt="Image"></a> 
                                       <a href="https://play.google.com/store/apps/details?id=com.jcom" target="_blank"> <img src="assets/img/googleplay.png" style="width: 198px;margin-top: 10px;margin-left: 10px;"  alt="Image"></a>
                                                                     
                                    
<!--                                     
                                    <div class="wcu-image text-center text-lg-left wow fadeInUp" data-wow-duration="1500ms"
                                    data-wow-delay="400ms">
                                   
                                    </div>                                     -->
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        

            


        </div>
    </section>

    
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
<app-header1></app-header1>

    <section class="faq-section section-gap">
        <div class="container">
            <!-- FAQ Page Title -->
   
             <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">Membership</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>   Members are individuals who are either active or associate or Alumni members of JCI, can apply for JCOM and have paid the current year subscription of JCOM & JCI India Approval
                    </p>
                    
                    <p>
                        <i class="fas fa-check"></i>One individual member can join only one live table, any number of virtual table and unlimited number of association tables On payment of corresponding subscriptions.
                    </p>
                </div>
            </div>

            <!-- FAQ LOOP -->
            <div class="accordion faq-loop grey-header" id="faqAccordion">
                <div class="card">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne">
                            Admission
                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseOne" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            The admission of members is a process which includes submission of application to concerned Table Chairman & payment of respective subscription followed by an approval by the National Head Quarters of JCOM 
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo">
                            Fees Structure
                        <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseTwo" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    For enrolling the new member, registration fees will be     Rs.3000 + 18% GST   (non- refundable)  per year
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The renewal fee of Rs 3000 + 18% GST should be paid before January 1st of every year
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The sitting fees will be fixed by the Executive Board and may vary from table to table
                                </li>
                    
                                <li>
                                    <i class="fas fa-check"></i>
                                    Every member should pay the registration fees before the enrollment
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The member has to pay the sitting fees on or before the meeting as decided        by their table executive board
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Any further expenses for the table will be collected as per the decision       of the Executive Board
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The terminated member based on the attendance can rejoin only by paying full registration fees if the executive committee of the table agrees
                                </li>
                              
                            </ul>  
                       </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree">
                            Attendance
                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseThree" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Attendance is most essential to continue the membership
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Maximum 6  absence are allowed in 6 months for the rolling period
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The person failing in the above criteria to be deemed to lose   their membership of JCOM
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Once the JCOM membership is lost, the website username and      password will be barred                                                 the table  
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    For the virtual meetings the attendance will be counted only if the   members      camera is switched on during the entire meeting. 
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Once the membership is terminated he/ she cannot attend any JCOM         meeting of any table.
                                </li>
                            
                            </ul>  
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseFour">
                            Dress Code

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseFour" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            The dress code for the JCOM  is full formal  as recommended by JCOM .
                        </div>
                    </div>
                </div>

                <!-- <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseFour1">
                            Category

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseFour1" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            Members who wish to change their category must submit a new membership application and obtain approval from the JCOM for that category change
                        </div>
                    </div>
                </div> -->

            
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree5">
                            Code of Conduct
                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseThree5" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The JCOM table member should maintain minimum code of conduct as                expected by the Executive Board and JCOM team                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                	In the absence of a Executive Board, The executive board may act as an ad-         hoc Executive Board until one is established
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    JCOM  Membership lists are for the purpose of 'giving' connects and not for soliciting (via e-mail, direct mail or other means) JCOM members without their prior approval.                                </li>
                    
                                    <li>
                                        <i class="fas fa-check"></i>
                                        All new members must attend the Member Onboarding training Program in their Zone   within the first 60 days of joining. Only after attending the training may the new member is added to the 'speaker rotation' for that Table.             
                                  </li>
                                  <li>
                                    <i class="fas fa-check"></i>
                                    Other than normal JCOM  printed materials, members may not use the JCOM  Intellectual Property (eg. logos, trademarks, names, slogans, copyrighted materials, etc.) to manufacture, distribute, sell, market, or promote any product or service, or otherwise use the JCOM Intellectual Property without obtaining the prior written consent of JCOM HQ or the appropriate Zone Office. Members must agree to abide by the JCOM Branding Standards for any permitted use.                              </li>
                            </ul>  
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree6">
                            Termination 
                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseThree6" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Apart from attendance criteria any member/officer of JCOM found violating or causing injuries to the JCOM  can be terminated from the  by issuing a Showcase notice with the 7 days time period of reply. If the reply is not found satisfactory by JCOM or by the JCOM board, the member can be terminated from JCOM. A member can be terminated directly by JCOM board                                <li>
                                  
                                </li>
                               
                            </ul>  
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree7">
                            Disclaimer 
                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseThree7" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The above guidelines will change/update with every monthly JCOM board       meeting.                                  
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    The members are requested to get the regular updates from the chairman                                </li>
                            </ul>  
                        </div>
                    </div>
                </div>

            
            </div>
            <!-- End Faq LOOP -->
        </div>
    </section>


    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
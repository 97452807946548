<app-header1></app-header1>


<section class="about-section about-illustration-img section-gap">
    <div class="container">
 
            <!-- FAQ Page Title -->
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">JCOM  Board</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>  JCOM board will consists of National President , Immediate Past National  President, National  Director Business, General Legal Council, National Treasurer, Secretary General, one EVP  and other members appointed by the National President for guiding JCOM 
                    </p>
                    <p>
                        <i class="fas fa-check"></i>  The term of office for the JCOM officers will be one year from January         1st to December 31st.
                    </p>
                    <p>
                        <i class="fas fa-check"></i>  The Chairperson of the JCOM board can be appointed by the National President. 
                    </p>
                    <p>
                        <i class="fas fa-check"></i> The Chairman can invite special invitees to JCOM  Board meeting  in consultation with the National President. 
                    </p>

					<p>
                        <i class="fas fa-check"></i> The JCOM National Board will meet once in 30 days either virtually or physically to look into the progress and activities of JCOM 
                    </p>

					<p>
                        <i class="fas fa-check"></i> The annual general body meeting of the JCOM will be held along           with the National convention 
                    </p>

					<p>
                        <i class="fas fa-check"></i> All the JCOM Zone Chairman will be the special invitee for the JCOM                          meetings
                    </p>
                </div>
            </div>




            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">JCOM Zone Board</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>JCOM Zone board will consists of Zone President, Immediate Past Zone  President, Zone Director Business, one ZVP  and other members appointed by the Zone President for guiding JCOM.

                    </p>
					<p>
                        <i class="fas fa-check"></i>The Chairperson of the JCOM board can be appointed by the Zone      President.  All the table Chairman and the coaches of that particular Zone will be the part of the board.

                    </p>
					<p>
                        <i class="fas fa-check"></i>The Chairman can invite special invitees to JCOM  Board                         meeting or to the table meeting in consultation with National President

                    </p>

					<p>
                        <i class="fas fa-check"></i>The JCOM Zone  Board will meet once in 30 days either virtually or physically to look into the progress and activities of JCOM in that Zone 

                    </p>

					<p>
                        <i class="fas fa-check"></i>The annual general body meeting of the JCOM Zone  will be the JCOM Zone level Business conclave happening once in six months.

                    </p>

					<p>
                        <i class="fas fa-check"></i>The term of office for the JCOM Zone will be six months.         
						The period shall be for Jan 1st to June 30th, the next period                                                                                                  starts from July 1st to Dec 31st.
						

                    </p>
                  
                </div>
            </div>



            
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-12">
                    <div class="section-title left-border">
                         <h2 class="title">JCOM Table Executive Board</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <br>
                    <p>
                        <i class="fas fa-check"></i>Each table of a JCOM will have executive board.
                    </p>
					<p>
                        <i class="fas fa-check"></i>Each board will have
                    </p>
                    <p>
                        a)	Chairman
                    </p>
                    <p>
                        b)	Vice Chairman
                    </p>
                    <p>
                        c)	Secretary
                    </p>
                    <p>
                        d)	Treasurer
                    </p>
                    <p>
                       e)	2 Directors
                    </p>
                    <p>
                        f)	Table Coach
                     </p>
					<p>
                        <i class="fas fa-check"></i>Executive board duration will be for 12 months.
                    </p>
					<p>
                        <i class="fas fa-check"></i>The period shall be for Jan 1st to Dec 31st
                    </p>
                </div>
            </div>

   
      


       

     

</div>
</section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <!-- <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">About Us</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section> -->
    <!--====== Breadcrumb part End ======-->

    <!--====== About Section start ======-->

    <section class="about-section about-illustration-img section-gap">
        <div class="container">
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="tab-mission" role="tabpanel">
                    <div class="tab-text-block left-image with-left-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10">
                                <div class="block-image">
                                    <img src="assets/img/feature/07.png" alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10">
                                <div class="block-text">
                                    
                                   
                                    <h3>Vision</h3>
                                    <p>
                                       <li> To be the Leading Chamber of Commerce of Young JCI Entrepreneurs</li>
                                    </p>
                                    <ul>
                                        <p>
                                            <br><br>
                                        </p>    
                                    
                                    </ul>
                                    <h3 >Mission</h3>
                                    <p>
                                        <li>To provide Business Development opportunities that empower young Entrepreneurs
                                        towards Business Growth.
                                        </li>
                                        <li>Developing Business Leaders for a Changing World.
                                            </li>
                                    </p>

                                    <ul>
                                        
                                    
                                    </ul>
                              
                                  
                                </div>
                            </div>
                        </div>
                        <p>
                            <br><br>
                        </p>    
                    
                        <!-- <h3>Membership Oath</h3>
                        <p>
I do Promise that i Will Faithfully Serve the purposes of JCOM, and Will Be truthful with members and customers in providing the quality service by Following the ethics of good business with the guidelines of JCOM

                        </p> -->

                        <ul>
                            <p>
                                <br><br>
                            </p>    
                        
                        
                        </ul>
                        <!-- <h3>Officers Oath</h3>
                        <p>
                            I do solemnly Swear that i will Faithfully serve the purposes of JCOM and will to the best of my Abilty serve as a Living Example of JCOM philosophy and belief and will uphold and enforce the guidelines and policies of JCOM at all times
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
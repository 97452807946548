<header class="sticky-header">

	<style>
		.active-link{
			background-color: #01537b;
			color: white !important;
		  }
		 
	 </style>
		<!-- Header Menu  -->
		<div class="header-nav">
			<div class="container-fluid container-1600">
				<div class="nav-container">
					<!-- Site Logo -->
					<div class="site-logo">
						<a routerLink=''> <img src="assets/img/logo.png" alt="Logo"></a>
					</div>

					<!-- Main Menu -->
					<div class="nav-menu d-lg-flex align-items-center">

						<!-- Navbar Close Icon -->
						<div class="navbar-close">
							<div class="cross-wrap"><span></span><span></span></div>
						</div>

						<!-- Mneu Items -->
						<div class="menu-items">
							<ul>
								<li class="submenu">
									<a  routerLink='/' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" >Home</a>
									<!-- <ul class="submenu">
										<li><a routerLink='/' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home One</a></li>
										<li><a routerLink='/homepage2' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Two</a> </li>
										<li><a routerLink='/homepage3' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
									</ul> -->
								</li>
								<li class="has-submemu">
									<a   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About JCOM</a>
									<ul class="submenu">
										<li><a routerLink='/about' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About JCOM</a></li>
										<li><a routerLink='/visionmission' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Vision&Mission</a> </li>
										<li><a routerLink='/jcomworks' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">How JCOM Works</a></li>
										<li><a routerLink='/jcomwork' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">JCOM Board</a></li>
										<li><a routerLink='/tabletypes' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Table Types</a></li>
									</ul>
								</li>
								<li class="submenu">
									<a routerLink='/table' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Browse Tables</a>
									<!-- <ul class="submenu">
										<li><a routerLink='/service-two' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services Two</a></li>
										<li><a routerLink='/service-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
									</ul> -->
								</li>
								<li class="submenu">
									<a routerLink='/membership' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Membership</a>
									<!-- <ul class="submenu">
										<li><a routerLink='/blog-standard' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
										<li><a routerLink='/blog-grid' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
										<li><a routerLink='/blog-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
									</ul> -->
								</li>
								<li class="submenu">
									<a routerLink='/training' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Training</a>
									<!-- <ul class="submenu">
										<li><a routerLink='/portfolio-two' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Portfolio Two</a></li>
										<li><a routerLink='/portfolio-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Portfolio Details</a></li>
									</ul> -->
								</li>
							


								<li><a routerLink='/starttable' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Start Table?</a></li>


								<!-- <li><a routerLink='/register' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Register</a></li> -->




								<!-- <li class="has-submemu">
									<a   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Join Now</a>
									<ul class="submenu">
										<li><a  href ="https://play.google.com/store/apps/details?id=com.jcom" target="_blank" rel="noopener noreferrer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Play Store</a></li>
										<li><a  href="https://apps.apple.com/us/app/jcom/id1582305234" target="_blank" rel="noopener noreferrer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">App Store</a> </li>
									</ul>
								</li> -->


							


								<li><a routerLink='/contact-us' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
							</ul>
						</div>
						<!-- Pushed Item -->
						<div class="nav-pushed-item"></div>
					</div>

					<!-- Navbar Extra  -->
					<div class="navbar-extra d-lg-block d-flex align-items-center">
						<!-- Navbtn -->
						<div  >
							<a routerLink='/'> <img src="assets/img/logo_jci.png" alt="Logo" style="width: 65%;" ></a>
						</div>
						<!-- Navbar Toggler -->
						<div class="navbar-toggler">
							<span></span><span></span><span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
<app-header1></app-header1>

<section class="about-section about-illustration-img section-gap">
    <div class="container">
        <h1 class="page-title" style="text-align: center;">Terms and Conditions</h1>
        
      <div class="container-fluid">
                <div class="row-fluid">
                        
        <h2>JCOM</h2>
        <p>Building trusted relationships is critical to business success. The purpose of Business Networking through JCOM (Jaycees Chamber of Commerce) is to provide a platform to JCI India members to meet the  other business owners, potential suppliers, or other experienced business professionals, to help you grow your business with the help of the network of Junior Chamber International. Networking gives you a pool of experts who go from the competition to the customers and allows you to offer them something, hopefully in exchange for their services, advice, knowledge, or contacts.
            <br>
            Basically, JCOM  operate on the philosophy of giving people will benefit. This means the more referrals you give to fellow members, the more referrals will be given back to you. The JCOM  Membership is designed to develop long-lasting relationships that deepen and evolve through their core value – giving people will benefit. In JCOM, each member of the referral network contributes to the success.</p>
        <h2>The mobile application of JCOM</h2>
   
        <p>This TOU govern your access to and use of JCOM’s services available via the JCOM Mobile, once you become enrolled as the member of JCOM. The Mobile App includes SMS, email notifications, applications, buttons, widgets, ads, commerce services, and any other related services that can be accessed via our Site or that link to these TOU (collectively, the “Services“), and any information, text, links, graphics, photos, videos, or other materials or templates of materials uploaded, downloaded or appearing on the Services (collectively referred to as “Content“). By using the JCOM Mobile Application and Services you agree to be bound by these TOU. The Mobile App,  their past, present and future versions; all web pages found within the Web Sites; the materials and information on the Web Sites, including, without limitation, organization, graphics, text, images, audio, videos, designs, compilations, advertising copy, and the trademarks, logos, domain names, trade names, service marks and trade identities; any and all copyrightable material (including source and object code); and all other materials related to the Web Sites, including without limitation, (collectively, the “Materials”) are provided by JCOM. By using the Web Site and mobile application,  you represent that: (i) you have the capacity to be bound by these TOU or, if you are a minor, your parent or legal guardian has read and agreed to these TOU on your behalf; (ii) all information you provide to JCOM is true, accurate, complete and current; (iii) you hold and will continue to hold all rights necessary to enter into and fully perform your obligations under these TOU and to grant the rights granted under these TOU; </p>
        <h2>Who Can be the members</h2>
        <p>Individuals who are either active or associate or Alumni members of JCI India can only become a member of JCOM, provided he / she have paid the subscription charges of JCOM<br>
            Individuals who are either active or associate or Alumni members of JCI India can only become a member of JCOM, provided he / she have paid the subscription charges of JCOM</p>
        
        <h2>Membership subscription and other payments.
        </h2>
        <p>The membership subscription of JCOM will be decided by JCI India from time to time, at its National Governing Board meeting / JCOM Board. The other charges including the meeting fees and other payments with regard to each table will be decided by the JCOM Table<br>
        a) Online Services:-<br>
        When you seek an online service, the online payment options
        will be sent to you. Those prices, availability, and other purchase
        terms are subject to change. JCOM reserves the right without
        prior notice to discontinue or change specifications and prices
        on services offered on and outside of the jcombiz.com without
        incurring any obligation to you.<br>
        b) All fees may be subject to taxes.<br>
        c) Purchases of other products and services through the
        jcombiz.com may be subject to other terms and conditions
        that are presented to you at the time of purchase.<br>
        <h2>Refund Policy</h2>
        <p>Our refund policy acknowledges that subject to applicable membership policies of JCOM,  if the payment is already done by you, and if your desired services are not delivered, JCOM will refund any payment you made for the service that has not been delivered. Any decision on refund is sole discretion of the JCOM Board, if any dispute arise upon any matter</p>
        <h2>Privacy Policy.</h2>
        <p>You acknowledge that JCOM may access, preserve and disclose your
        information if required to do so by law or in a good faith belief that
        such access preservation or disclosure is reasonably necessary to:
        (a) comply with legal process; <br>(b) enforce the provisions of this TOU;
        <br>(c) respond to claims that any content violates the rights of third
        parties; <br>(d) respond to your requests for customer service; or <br>(e)
        protect the rights, property or personal safety of JCOM, its users and
        the public.<br>
        Our Privacy Policy, describes how we collect, process, store and
        share the information you provide to us when you use jcombiz.com
        and Services. You acknowledge that through your use of the Services,
        your personal data will be collected and used (as set forth in the
        Privacy Policy), including the transfer of your data to the servers kept
        at Amazon Web Services, and/or other countries for storage, processing and use by
        JCOM.</p>
        <h2>Content of the Services.</h2>
        <p>You are responsible for your use of the JCOM Site and Services and
        for any Content you provide, including compliance with applicable
        laws, rules, and regulations. Before accepting or joining any content
        published via the Internet, you must consider the source of the
        Content or materials before using or relying upon it.<br>
        JCOM is an intellectual property owner and understands the costs and
        expenses in developing and maintaining intellectual property. JCOM
        also respects the intellectual property rights of others and expects its
        users of the JCOM Site and Services to do the same. We reserve the
        right to remove Content alleged to be infringing without prior notice,
        at our sole discretion, and without liability to you.<br>
        Users of the Services will operate in a lawful, ethical and professional
        manner. Users shall conduct themselves in a fair, responsible and
        business-like manner at all times and will avoid any discourteous,
        deceptive, misleading or unethical practices. You are solely
        responsible for any comments or posts you leave on our Site, blogs
        or any JCOM site. JCOM does not control content posted and does not
        guarantee the accuracy, integrity or quality of the content. In using the
        Site or the Services you may be exposed to content that you may find
        offensive, indecent or objectionable. Under no circumstances will
        JCOM be liable in any way for any content, including but not limited to
        any errors or omissions in any content or any loss or damage of any
        kind incurred as a result of the use of any content posted or otherwise
        made available via the Site or the Services.<br>
        All kind of illegal mis use of the website jcombiz.com and the online
        content of JCOM, including spamming is strictly prohibited by JCOM
        and will result in the immediate termination of your account. Such
        practices may also be illegal under applicable laws and may subject
        you to civil or criminal penalties.<br>
        Your use of software made available via jcombiz.com is subject to any
        applicable license agreement or user agreement or the
        documentation that accompanies or is included with the software
        (“License Terms“).<br>
        JCOM has the right (but not the obligation) in its sole discretion to
        screen, refuse, move or remove any content that violates the TOU or
        is otherwise objectionable.<br>
        You bear all risks associated with the use of any content on the
        jcombiz.com or in connection with the Services including any reliance
        on the accuracy, completeness, or usefulness of such content.
        The Services and software embodied within the Service may include
        security components that permit digital materials to be protected. The
        use of these materials is subject to usage rules set by JCOM and/or
        its technology providers. You may not attempt to override or
        circumvent any of the usage rules embedded in the Services.</p>
        <h2>General Use of the Service – Permissions and Restrictions –</h2>
        <p>JCOM ” hereby grants you permission to access and use the Service as set forth in these Terms of Service, provided that
            You agree not to distribute in any medium any part of the Service or the Content without JOM ‘s prior written authorization, unless JCOM makes available the means for such distribution through functionality offered by the Service (such as the Embeddable Player).
            You agree not to alter or modify any part of the Service.
            You agree not to access Content through any technology or means.
            You agree not to use the Service for any of the following commercial uses unless you obtain JCOM ‘s prior written approval:
             
            the sale of access to the Service;
            the sale of advertising, sponsorships, or promotions placed on or within the Service or Content; or
            the sale of advertising, sponsorships, or promotions on any page of an ad-enabled blog or website containing Content delivered via the Service, unless other material not obtained from JCOM appears on the same page and is of sufficient value to be the basis for such sales.
            You agree not to use or launch any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that accesses the Service in a manner that sends more request messages to the JCOM servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser. JCOM reserves the right to revoke these exceptions either generally or in specific cases. You agree not to collect or harvest any personally identifiable information, including account names, from the Service, nor to use the communication systems provided by the Service (e.g., comments, email) for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any users of the Service with respect to their Content.
            In your use of the Service, you will comply with all applicable laws.
            JCOM reserves the right to discontinue any aspect of the Service at any time without giving any reasons for such discontinuance.
            Your Use of Content – In addition to the general restrictions herein, it may be noted that any information, text links, graphics, photo, videos or any other materials or arrangement of material uploaded, downloaded or appearing on the Services (“the Content”) shall become the property of JCOM.
            The Content on the Service, and the trademarks, service marks and logos (“Marks”) on the Service, are owned by or licensed to JCOM, subject to copyright and other intellectual property rights under the law.
            
        </p>

        <h2>Intellectual Property</h2>
        <p>All content included on the jcombiz.com , mobile application of JCOM
            and in connection with the Services such as text, graphics, logos,
            button icons, images, audio clips, digital downloads, data compilations
            and software, is the property of JCOM or its content suppliers and is
            protected by international copyright laws. All software used on the
            Site is the property of JCOM or its software suppliers and is protected
            by international copyright laws. All of the trademarks, service marks,
            logos, brand and trade names appearing on the Site are the
            proprietary intellectual property of the owners of such marks, logos
            or names and you may not use, modify, remove or otherwise infringe
            any of such proprietary intellectual property. JCOM retains full
            ownership rights with respect to the Site and the Services including 
            but not limited to design, functionality, and documentation. You may
            not copy, edit, or reproduce any part of the Site or the Services.
            </p>
        
            <h2>Indemnity and Defense.</h2>
            <p>You will defend, indemnify and hold harmless JCI India, JCOM and its
                affiliates (and their respective office bearers, officers, and
                representatives) from and against any and all claims, costs, losses,
                damages, judgments, penalties, interest and expenses (including
                reasonable attorneys’ fees) that arise out of or relate to:<br> (i) your use
                of the Site or the Services;<br> (ii) any actual or alleged breach of your
                representations, warranties, or obligations set forth in this TOU;<br> (iii)
                any content you provide including but not limited to any actual or
                alleged infringement of any intellectual property or proprietary rights
                of any third party.
                </p>    
                
                <h2>Limitation of Liability.</h2>
                <p>JCOM will not be liable for direct or indirect damages of any kind,
                    including without limitation incidental, punitive or consequential
                    damage or loss arising out of or in connection with this TOU, the Site,
                    the Services, inability to use the Site or the Services, or resulting from
                    any goods or services obtained or messages received or transactions
                    entered into through the Site or the Services.
                    </p>   
                    
                    
                    <h2>Disclaimer of Warranties.</h2>
                    <p>JCOM makes no representations or warranties of any kind, express
                        or implied, concerning the Site, the Services or the content thereof. To
                        the fullest extent permissible under applicable law JCOM disclaims
                        any and all such warranties including without limitation:
                        </p>   
        
                        <h2>Online Notices.</h2>
                    <p>By using the jcombiz.com or the Services you consent to electronically
                        receive from JCOM any communications including notices, 
                        agreements, legally required disclosures or other information in
                        connection with the Services. JCOM may also provide such notices by
                        posting them on the Site. If you desire to withdraw your consent to
                        receive notices electronically you must discontinue your use of Site
                        and the Services.
                        </p>   
        
                        <h2>Severability.</h2>
                        <p>In the event that any provision of this TOU is held to be invalid or
                            unenforceable the remaining provisions of this TOU will remain in full
                            force and effect.
                            </p>  
                            <h2>Waiver.</h2>
                            <p>JCOM will not be considered to have waived any of rights or remedies
                                described in this TOU unless the waiver is in writing and signed by
                                JCOM. No delay or omission by us in exercising our rights or remedies
                                will impair or be construed as a waiver. Any single or partial exercise
                                of a right or remedy will not preclude further exercise of any other
                                right or remedy. Our failure to enforce the strict performance of any
                                provision of this TOU will not constitute a waiver of JCOM’s right to
                                subsequently enforce such provision or any other provisions of this
                                TOU.
                                </p>  
        
                                <h2>Force Majeure.</h2>
                            <p>In addition to any excuse provided by applicable law, we shall be
                                excused from liability for non-delivery or delay in delivery of Services
                                available through our Site arising from any event beyond our
                                reasonable control, whether or not foreseeable by either party,
                                including but not limited to: pandamics, war, fire, accident, adverse
                                weather, inability to secure transportation, governmental act or
                                regulation, and other causes or events beyond our reasonable control,
                                whether or not similar to those which are enumerated above.
                                </p>  
        
        
                                <h2>Revisions to TOU.</h2>
                                <p>JCOM reserves the right to modify or add to these TOU at any time
                                    without prior notice (“Updated TOU”). You agree that we may notify you
                                    of the Updated TOU by posting them on the Web Sites so that they are
                                    accessible via a link on the home page of the Web Site, and that your
                                    use of the Web Site after we have posted the Updated TOU (or
                                    engaging in such other conduct as we may reasonably specify)
                                    constitutes your agreement to the Updated TOU. Therefore, you should
                                    review these TOU before using the Web Sites. The Updated TOU will be
                                    effective as of the time of posting, or such later date as may be
                                    specified in the Updated TOU, and will apply to your use of the Web
                                    Sites from that point forward.
                                    </p>  
        
        
                                    <h2>Termination</h2>
                                <p>JCOM may terminate your Membership and use of the Web Site and
                                    Mobile Applicaiton at any time and for any reason, with or without
                                    cause, without prior notice to you and without any liability or further
                                    obligation of any kind whatsoever to you or any other party. JCOM also
                                    reserves the right to use any technological, legal, operational, or other
                                    means available to enforce these TOU, including without limitation,
                                    blocking specific IP addresses or access to the Web Sites.
                                    </p>  
                </div>
            </div>
     
    </div>
</section>



    <app-footer1></app-footer1>